import { AuthorizeStep }    from 'aurelia-auth';
import { inject }           from 'aurelia-framework';
import { PLATFORM }         from 'aurelia-pal';
import { Router }           from 'aurelia-router';
import { MenuAccessStep }   from 'resources/services/menu-access-step';
import { PostCompleteStep } from 'resources/services/post-complete-step';
import environment          from '../../../config/environment.json';

@inject(Router)
export class RouterConfig {

    /**
     * Constructor
     *
     * @param router
     */
    constructor(router) {
        this.router = router;
    }

    /**
     * Configure router
     */
    configure() {
        let appRouterConfig = (config) => {
            config.title = environment.productName;

            config.addPipelineStep('authorize', AuthorizeStep);
            config.addPreActivateStep(MenuAccessStep);
            config.addPipelineStep('postcomplete', PostCompleteStep);

            config.map([
                // ==================== maintenance ====================
                {
                    route:    ['maintenance'],
                    name:     'maintenance',
                    moduleId: PLATFORM.moduleName('maintenance'),
                    nav:      true,
                    title:    'Maintenance',
                },

                // ==================== dashboard ====================
                {
                    route:    ['', 'dashboard'],
                    name:     'dashboard',
                    moduleId: PLATFORM.moduleName('modules/core/dashboard/index'),
                    nav:      true,
                    title:    'Dashboard',
                },

                // ==================== concessions » lot-intervention-selection ====================
                {
                    route:    'concessions/lot-intervention-selection',
                    name:     'concessions.lot-intervention-selection.index',
                    moduleId: PLATFORM.moduleName('modules/concessions/lot-intervention-selection/index'),
                    nav:      true,
                    title:    'Seleção de Lote/Intervenção',
                },

                // ==================== account-settings ====================
                {
                    route:    'account-settings',
                    name:     'core.account-settings.index',
                    moduleId: PLATFORM.moduleName('modules/core/account-settings/index'),
                    nav:      false,
                    title:    'Definições da conta',
                    settings: {
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.account-settings' },
                        ],
                    },
                },

                // ==================== personal area » messages ====================
                {
                    route:    'personal-area/messages',
                    name:     'personal-area.messages.index',
                    moduleId: PLATFORM.moduleName('modules/personal-area/messages/index'),
                    nav:      true,
                    title:    'Mensagens',
                    settings: {
                        menu:        'personal-area',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.personal-area' },
                            { title: 'page.breadcrumbs.messages' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'personal-area/messages/create',
                    name:     'personal-area.messages.create',
                    moduleId: PLATFORM.moduleName('modules/personal-area/messages/create/index'),
                    nav:      false,
                    title:    'Criar mensagem',
                    settings: {
                        menu:        'personal-area',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.personal-area' },
                            { title: 'page.breadcrumbs.messages' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'personal-area/messages/:id/details',
                    name:     'personal-area.messages.details',
                    moduleId: PLATFORM.moduleName('modules/personal-area/messages/details/index'),
                    nav:      false,
                    title:    'Detalhes da mensagem',
                    settings: {
                        menu:        'personal-area',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.personal-area' },
                            { title: 'page.breadcrumbs.messages' },
                            { title: 'page.breadcrumbs.details' },
                        ],
                    },
                },

                // ==================== personal area » notifications ====================
                {
                    route:    'personal-area/notifications',
                    name:     'personal-area.notifications.index',
                    moduleId: PLATFORM.moduleName('modules/personal-area/notifications/index'),
                    nav:      true,
                    title:    'Notificações',
                    settings: {
                        menu:        'personal-area',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.personal-area' },
                            { title: 'page.breadcrumbs.notifications' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'personal-area/notifications/:id/details',
                    name:     'personal-area.notifications.details',
                    moduleId: PLATFORM.moduleName('modules/personal-area/notifications/details/index'),
                    nav:      false,
                    title:    'Detalhes da notificação',
                    settings: {
                        menu:        'personal-area',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.personal-area' },
                            { title: 'page.breadcrumbs.notifications' },
                            { title: 'page.breadcrumbs.details' },
                        ],
                    },
                },

                // ==================== administration » users ====================
                {
                    route:    'administration/users',
                    name:     'administration.users.index',
                    moduleId: PLATFORM.moduleName('modules/administration/users/index'),
                    nav:      true,
                    title:    'Utilizadores',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.administration' },
                            { title: 'page.breadcrumbs.users' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'administration/users/create',
                    name:     'administration.users.create',
                    moduleId: PLATFORM.moduleName('modules/administration/users/create/index'),
                    nav:      false,
                    title:    'Criar utilizador',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.administration' },
                            { title: 'page.breadcrumbs.users' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'administration/users/:id/edit',
                    name:     'administration.users.edit',
                    moduleId: PLATFORM.moduleName('modules/administration/users/edit/index'),
                    nav:      false,
                    title:    'Editar utilizador',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.administration' },
                            { title: 'page.breadcrumbs.users' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'administration/users/:id/view',
                    name:     'administration.users.view',
                    moduleId: PLATFORM.moduleName('modules/administration/users/view/index'),
                    nav:      false,
                    title:    'Visualizar utilizador',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.administration' },
                            { title: 'page.breadcrumbs.users' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== administration » roles ====================
                {
                    route:    'administration/roles',
                    name:     'administration.roles.index',
                    moduleId: PLATFORM.moduleName('modules/administration/roles/index'),
                    nav:      true,
                    title:    'Perfis',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.administration' },
                            { title: 'page.breadcrumbs.roles' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'administration/roles/create',
                    name:     'administration.roles.create',
                    moduleId: PLATFORM.moduleName('modules/administration/roles/create/index'),
                    nav:      false,
                    title:    'Criar perfil',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.administration' },
                            { title: 'page.breadcrumbs.roles' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'administration/roles/:id/edit',
                    name:     'administration.roles.edit',
                    moduleId: PLATFORM.moduleName('modules/administration/roles/edit/index'),
                    nav:      false,
                    title:    'Editar perfil',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.administration' },
                            { title: 'page.breadcrumbs.roles' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'administration/roles/:id/view',
                    name:     'administration.roles.view',
                    moduleId: PLATFORM.moduleName('modules/administration/roles/view/index'),
                    nav:      false,
                    title:    'Visualizar perfil',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.administration' },
                            { title: 'page.breadcrumbs.roles' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== administration » translations ====================
                {
                    route:    'administration/translations',
                    name:     'administration.translations.index',
                    moduleId: PLATFORM.moduleName('modules/administration/translations/index'),
                    nav:      true,
                    title:    'Traduções',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.administration' },
                            { title: 'page.breadcrumbs.translations' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'administration/translations/create',
                    name:     'administration.translations.create',
                    moduleId: PLATFORM.moduleName('modules/administration/translations/create/index'),
                    nav:      false,
                    title:    'Criar tradução',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.administration' },
                            { title: 'page.breadcrumbs.translations' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'administration/translations/:id/edit',
                    name:     'administration.translations.edit',
                    moduleId: PLATFORM.moduleName('modules/administration/translations/edit/index'),
                    nav:      false,
                    title:    'Editar tradução',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.administration' },
                            { title: 'page.breadcrumbs.translations' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },

                // ==================== administration » settings ====================
                {
                    route:    'administration/settings/',
                    name:     'administration.settings.index',
                    moduleId: PLATFORM.moduleName('modules/administration/settings/index'),
                    nav:      false,
                    title:    'Definições',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.administration' },
                            { title: 'page.breadcrumbs.app-settings' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },

                // ==================== administration » maintenances ====================
                {
                    route:    'administration/maintenances',
                    name:     'administration.maintenances.index',
                    moduleId: PLATFORM.moduleName('modules/administration/maintenances/index'),
                    nav:      true,
                    title:    'Manutenções',
                    settings: {
                        menu:        'maintenances',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.administration' },
                            { title: 'page.breadcrumbs.maintenances' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'administration/maintenances/create',
                    name:     'administration.maintenances.create',
                    moduleId: PLATFORM.moduleName('modules/administration/maintenances/create/index'),
                    nav:      false,
                    title:    'Programar Manutenção',
                    settings: {
                        menu:        'maintenances',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.administration' },
                            { title: 'page.breadcrumbs.maintenances' },
                            { title: 'page.breadcrumbs.program-maintenance' },
                        ],
                    },
                },
                {
                    route:    'administration/maintenances/:id/edit',
                    name:     'administration.maintenances.edit',
                    moduleId: PLATFORM.moduleName('modules/administration/maintenances/edit/index'),
                    nav:      false,
                    title:    'Editar Manutenção',
                    settings: {
                        menu:        'maintenances',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.administration' },
                            { title: 'page.breadcrumbs.maintenances' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'administration/maintenances/:id/view',
                    name:     'administration.maintenances.view',
                    moduleId: PLATFORM.moduleName('modules/administration/maintenances/view/index'),
                    nav:      false,
                    title:    'Visualizar Manutenção',
                    settings: {
                        menu:        'maintenances',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.administration' },
                            { title: 'page.breadcrumbs.maintenances' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== administration » mail-settings ====================
                //{
                //    route:    'administration/mail-settings/edit',
                //    name:     'administration.mail-settings.edit',
                //    moduleId: PLATFORM.moduleName('modules/administration/mail-settings/edit/index'),
                //    nav:      true,
                //    title:    'Configurações de email',
                //    settings: {
                //        menu:        'administration',
                //        breadcrumbs: [
                //            {title: 'page.breadcrumbs.administration'},
                //            {title: 'page.breadcrumbs.mail-settings'},
                //            {title: 'page.breadcrumbs.edit-record'},
                //        ],
                //    },
                //},

                // ==================== administration » helping-system ====================
                {
                    route:    'administration/helping-system',
                    name:     'administration.helping-system.index',
                    moduleId: PLATFORM.moduleName('modules/administration/resource-fields/index'),
                    nav:      true,
                    title:    'Sistema de ajuda',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.administration' },
                            { title: 'page.breadcrumbs.helping-system' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'administration/helping-system/:id/edit',
                    name:     'administration.helping-system.edit',
                    moduleId: PLATFORM.moduleName('modules/administration/resource-fields/edit/index'),
                    nav:      false,
                    title:    'Editar ajuda',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.administration' },
                            { title: 'page.breadcrumbs.helping-system' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'administration/helping-system/:id/view',
                    name:     'administration.helping-system.view',
                    moduleId: PLATFORM.moduleName('modules/administration/resource-fields/view/index'),
                    nav:      false,
                    title:    'Visualizar ajuda',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.administration' },
                            { title: 'page.breadcrumbs.helping-system' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== administration » consents ====================
                {
                    route:    'administration/consents',
                    name:     'administration.consents.index',
                    moduleId: PLATFORM.moduleName('modules/administration/consents/index'),
                    nav:      true,
                    title:    'Consentimentos',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.administration' },
                            { title: 'page.breadcrumbs.consents' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'administration/consents/create',
                    name:     'administration.consents.create',
                    moduleId: PLATFORM.moduleName('modules/administration/consents/create/index'),
                    nav:      false,
                    title:    'Criar consentimento',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.administration' },
                            { title: 'page.breadcrumbs.consents' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'administration/consents/:id/edit',
                    name:     'administration.consents.edit',
                    moduleId: PLATFORM.moduleName('modules/administration/consents/edit/index'),
                    nav:      false,
                    title:    'Editar consentimento',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.administration' },
                            { title: 'page.breadcrumbs.consents' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'administration/consents/:id/view',
                    name:     'administration.consents.view',
                    moduleId: PLATFORM.moduleName('modules/administration/consents/view/index'),
                    nav:      false,
                    title:    'Visualizar consentimento',
                    settings: {
                        menu:        'administration',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.administration' },
                            { title: 'page.breadcrumbs.consents' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== management » specifications » concessions ====================
                {
                    route:    'management/specifications/concessions',
                    name:     'management.specifications.concessions.index',
                    moduleId: PLATFORM.moduleName('modules/management/specifications/concessions/index'),
                    nav:      true,
                    title:    'Concessões',
                    settings: {
                        menu:        'specifications',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.specifications' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'management/specifications/concessions/create',
                    name:     'management.specifications.concessions.create',
                    moduleId: PLATFORM.moduleName('modules/management/specifications/concessions/create/index'),
                    nav:      false,
                    title:    'Criar Concessão',
                    settings: {
                        menu:        'specifications',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.specifications' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/specifications/concessions/:id/edit',
                    name:     'management.specifications.concessions.edit',
                    moduleId: PLATFORM.moduleName('modules/management/specifications/concessions/edit/index'),
                    nav:      false,
                    title:    'Editar Concessão',
                    settings: {
                        menu:        'specifications',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.specifications' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/specifications/concessions/:id/view',
                    name:     'management.specifications.concessions.view',
                    moduleId: PLATFORM.moduleName('modules/management/specifications/concessions/view/index'),
                    nav:      false,
                    title:    'Visualizar Concessão',
                    settings: {
                        menu:        'specifications',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.specifications' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== management » specifications » specifications ====================
                {
                    route:    'management/specifications/specifications',
                    name:     'management.specifications.specifications.index',
                    moduleId: PLATFORM.moduleName('modules/management/specifications/specifications/index'),
                    nav:      true,
                    title:    'Cadernos de Encargos',
                    settings: {
                        menu:        'specifications',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.specifications' },
                            { title: 'page.breadcrumbs.specifications' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'management/specifications/specifications/create',
                    name:     'management.specifications.specifications.create',
                    moduleId: PLATFORM.moduleName('modules/management/specifications/specifications/create/index'),
                    nav:      false,
                    title:    'Criar Cadernos de Encargo',
                    settings: {
                        menu:        'specifications',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.specifications' },
                            { title: 'page.breadcrumbs.specifications' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/specifications/specifications/:id/edit',
                    name:     'management.specifications.specifications.edit',
                    moduleId: PLATFORM.moduleName('modules/management/specifications/specifications/edit/index'),
                    nav:      false,
                    title:    'Editar Cadernos de Encargo',
                    settings: {
                        menu:        'specifications',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.specifications' },
                            { title: 'page.breadcrumbs.specifications' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/specifications/specifications/:id/view',
                    name:     'management.specifications.specifications.view',
                    moduleId: PLATFORM.moduleName('modules/management/specifications/specifications/view/index'),
                    nav:      false,
                    title:    'Visualizar Cadernos de Encargo',
                    settings: {
                        menu:        'specifications',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.specifications' },
                            { title: 'page.breadcrumbs.specifications' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== management » specifications » sectors ====================
                {
                    route:    'management/specifications/sectors',
                    name:     'management.specifications.sectors.index',
                    moduleId: PLATFORM.moduleName('modules/management/specifications/sectors/index'),
                    nav:      true,
                    title:    'Setores',
                    settings: {
                        menu:        'specifications',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.specifications' },
                            { title: 'page.breadcrumbs.sectors' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'management/specifications/sectors/create',
                    name:     'management.specifications.sectors.create',
                    moduleId: PLATFORM.moduleName('modules/management/specifications/sectors/create/index'),
                    nav:      false,
                    title:    'Criar setor',
                    settings: {
                        menu:        'specifications',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.specifications' },
                            { title: 'page.breadcrumbs.sectors' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/specifications/sectors/:id/edit',
                    name:     'management.specifications.sectors.edit',
                    moduleId: PLATFORM.moduleName('modules/management/specifications/sectors/edit/index'),
                    nav:      false,
                    title:    'Editar setor',
                    settings: {
                        menu:        'specifications',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.specifications' },
                            { title: 'page.breadcrumbs.sectors' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/specifications/sectors/:id/view',
                    name:     'management.specifications.sectors.view',
                    moduleId: PLATFORM.moduleName('modules/management/specifications/sectors/view/index'),
                    nav:      false,
                    title:    'Visualizar setor',
                    settings: {
                        menu:        'specifications',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.specifications' },
                            { title: 'page.breadcrumbs.sectors' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },
                // ==================== management » concessions » concessions ====================
                // ---------------- concessions ----------------
                {
                    route:    'management/concessions/concessions',
                    name:     'management.concessions.concessions.index',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/concessions-tree/index'),
                    nav:      true,
                    title:    'Concessões',
                    settings: {
                        menu:        'specifications',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.concessions-tree' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/concessions/create',
                    name:     'management.concessions.concessions.create',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/concessions-tree/concessions/create/index'),
                    nav:      false,
                    title:    'Criar Concessão',
                    settings: {
                        menu:        'concessions',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.concessions-tree' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/concessions/:id/edit',
                    name:     'management.concessions.concessions.edit',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/concessions-tree/concessions/edit/index'),
                    nav:      false,
                    title:    'Editar Concessão',
                    settings: {
                        menu:        'concessions',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.concessions-tree' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/concessions/:id/view',
                    name:     'management.concessions.concessions.view',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/concessions-tree/concessions/view/index'),
                    nav:      false,
                    title:    'Visualizar Concessão',
                    settings: {
                        menu:        'concessions',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.concessions-tree' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },
                // ---------------- highways ----------------
                {
                    route:    'management/concessions/highways/create',
                    name:     'management.concessions.highways.create',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/concessions-tree/highways/create/index'),
                    nav:      false,
                    title:    'Criar Auto-estrada',
                    settings: {
                        menu:        'concessions',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.concessions-tree' },
                            { title: 'page.breadcrumbs.highways' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/highways/:id/edit',
                    name:     'management.concessions.highways.edit',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/concessions-tree/highways/edit/index'),
                    nav:      false,
                    title:    'Editar Auto-estrada',
                    settings: {
                        menu:        'concessions',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.concessions-tree' },
                            { title: 'page.breadcrumbs.highways' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/highways/:id/view',
                    name:     'management.concessions.highways.view',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/concessions-tree/highways/view/index'),
                    nav:      false,
                    title:    'Visualizar Auto-estrada',
                    settings: {
                        menu:        'concessions',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.concessions-tree' },
                            { title: 'page.breadcrumbs.highways' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },
                // ---------------- intervention-types ----------------
                {
                    route:    'management/concessions/intervention-types/create',
                    name:     'management.concessions.intervention-types.create',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/concessions-tree/intervention-types/create/index'),
                    nav:      false,
                    title:    'Criar Intervenção',
                    settings: {
                        menu:        'concessions',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.concessions-tree' },
                            { title: 'page.breadcrumbs.intervention-types' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/intervention-types/:id/edit',
                    name:     'management.concessions.intervention-types.edit',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/concessions-tree/intervention-types/edit/index'),
                    nav:      false,
                    title:    'Editar Intervenção',
                    settings: {
                        menu:        'concessions',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.concessions-tree' },
                            { title: 'page.breadcrumbs.intervention-types' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/intervention-types/:id/view',
                    name:     'management.concessions.intervention-types.view',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/concessions-tree/intervention-types/view/index'),
                    nav:      false,
                    title:    'Visualizar Intervenção',
                    settings: {
                        menu:        'concessions',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.concessions-tree' },
                            { title: 'page.breadcrumbs.intervention-types' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },
                // ---------------- lot-interventions ----------------
                {
                    route:    'management/concessions/lot-interventions/create',
                    name:     'management.concessions.lot-interventions.create',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/concessions-tree/lot-interventions/create/index'),
                    nav:      false,
                    title:    'Criar Lote/Intervenção',
                    settings: {
                        menu:        'concessions',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.concessions-tree' },
                            { title: 'page.breadcrumbs.lot-interventions' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/lot-interventions/:id/edit',
                    name:     'management.concessions.lot-interventions.edit',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/concessions-tree/lot-interventions/edit/index'),
                    nav:      false,
                    title:    'Editar Lote/Intervenção',
                    settings: {
                        menu:        'concessions',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.concessions-tree' },
                            { title: 'page.breadcrumbs.lot-interventions' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/lot-interventions/:id/view',
                    name:     'management.concessions.lot-interventions.view',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/concessions-tree/lot-interventions/view/index'),
                    nav:      false,
                    title:    'Visualizar Lote/Intervenção',
                    settings: {
                        menu:        'concessions',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.concessions-tree' },
                            { title: 'page.breadcrumbs.lot-interventions' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },
                // ---------------- lot-constructions-oe ----------------
                {
                    route:    'management/concessions/lot-constructions/oe/create',
                    name:     'management.concessions.lot-constructions-oe.create',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/concessions-tree/lot-constructions/create/index'),
                    nav:      false,
                    title:    'Criar OE',
                    settings: {
                        menu:        'concessions',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.concessions-tree' },
                            { title: 'page.breadcrumbs.oe' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/lot-constructions/oe/:id/edit',
                    name:     'management.concessions.lot-constructions-oe.edit',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/concessions-tree/lot-constructions/edit/index'),
                    nav:      false,
                    title:    'Editar OE',
                    settings: {
                        menu:        'concessions',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.concessions-tree' },
                            { title: 'page.breadcrumbs.oe' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/lot-constructions/oe/:id/view',
                    name:     'management.concessions.lot-constructions-oe.view',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/concessions-tree/lot-constructions/view/index'),
                    nav:      false,
                    title:    'Visualizar OE',
                    settings: {
                        menu:        'concessions',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.concessions-tree' },
                            { title: 'page.breadcrumbs.oe' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },
                // ---------------- lot-constructions-oa ----------------
                {
                    route:    'management/concessions/lot-constructions/oa/create',
                    name:     'management.concessions.lot-constructions-oa.create',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/concessions-tree/lot-constructions/create/index'),
                    nav:      false,
                    title:    'Criar OA',
                    settings: {
                        menu:        'concessions',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.concessions-tree' },
                            { title: 'page.breadcrumbs.oa' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/lot-constructions/oa/:id/edit',
                    name:     'management.concessions.lot-constructions-oa.edit',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/concessions-tree/lot-constructions/edit/index'),
                    nav:      false,
                    title:    'Editar OA',
                    settings: {
                        menu:        'concessions',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.concessions-tree' },
                            { title: 'page.breadcrumbs.oa' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/lot-constructions/oa/:id/view',
                    name:     'management.concessions.lot-constructions-oa.view',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/concessions-tree/lot-constructions/view/index'),
                    nav:      false,
                    title:    'Visualizar OA',
                    settings: {
                        menu:        'concessions',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.concessions-tree' },
                            { title: 'page.breadcrumbs.oa' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },
                // ---------------- lot-constructions-oh ----------------
                {
                    route:    'management/concessions/lot-constructions/oh/create',
                    name:     'management.concessions.lot-constructions-oh.create',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/concessions-tree/lot-constructions/create/index'),
                    nav:      false,
                    title:    'Criar OH',
                    settings: {
                        menu:        'concessions',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.concessions-tree' },
                            { title: 'page.breadcrumbs.oh' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/lot-constructions/oh/:id/edit',
                    name:     'management.concessions.lot-constructions-oh.edit',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/concessions-tree/lot-constructions/edit/index'),
                    nav:      false,
                    title:    'Editar OH',
                    settings: {
                        menu:        'concessions',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.concessions-tree' },
                            { title: 'page.breadcrumbs.oh' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/lot-constructions/oh/:id/view',
                    name:     'management.concessions.lot-constructions-oh.view',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/concessions-tree/lot-constructions/view/index'),
                    nav:      false,
                    title:    'Visualizar OH',
                    settings: {
                        menu:        'concessions',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.concessions-tree' },
                            { title: 'page.breadcrumbs.oh' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },
                // ==================== management » concessions » lot-groups ====================
                {
                    route:    'management/concessions/lot-groups',
                    name:     'management.concessions.lot-groups.index',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/lot-groups/index'),
                    nav:      true,
                    title:    'Associações de lotes',
                    settings: {
                        menu:        'concessions',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.lot-groups' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/lot-groups/create',
                    name:     'management.concessions.lot-groups.create',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/lot-groups/create/index'),
                    nav:      false,
                    title:    'Criar associação de lotes',
                    settings: {
                        menu:        'concessions',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.lot-groups' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/lot-groups/:id/edit',
                    name:     'management.concessions.lot-groups.edit',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/lot-groups/edit/index'),
                    nav:      false,
                    title:    'Editar associação de lotes',
                    settings: {
                        menu:        'concessions',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.lot-groups' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/lot-groups/:id/view',
                    name:     'management.concessions.lot-groups.view',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/lot-groups/view/index'),
                    nav:      false,
                    title:    'Visualizar associação de lotes',
                    settings: {
                        menu:        'concessions',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.lot-groups' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },
                // ==================== management » concessions » entities ====================
                {
                    route:    'management/concessions/entities',
                    name:     'management.concessions.entities.index',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/entities/index'),
                    nav:      true,
                    title:    'Entidades',
                    settings: {
                        menu:        'concessions',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.entities' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/entities/create',
                    name:     'management.concessions.entities.create',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/entities/create/index'),
                    nav:      false,
                    title:    'Criar Entidade',
                    settings: {
                        menu:        'concessions',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.entities' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/entities/:id/edit',
                    name:     'management.concessions.entities.edit',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/entities/edit/index'),
                    nav:      false,
                    title:    'Editar Entidade',
                    settings: {
                        menu:        'concessions',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.entities' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/entities/:id/view',
                    name:     'management.concessions.entities.view',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/entities/view/index'),
                    nav:      false,
                    title:    'Visualizar Entidade',
                    settings: {
                        menu:        'concessions',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.entities' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },
                // ==================== management » concessions » teams ====================
                {
                    route:    'management/concessions/teams',
                    name:     'management.concessions.teams.index',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/teams/index'),
                    nav:      true,
                    title:    'Equipas',
                    settings: {
                        menu:        'concessions',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.teams' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/teams/create',
                    name:     'management.concessions.teams.create',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/teams/create/index'),
                    nav:      false,
                    title:    'Criar Equipa',
                    settings: {
                        menu:        'concessions',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.teams' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/teams/:id/edit',
                    name:     'management.concessions.teams.edit',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/teams/edit/index'),
                    nav:      false,
                    title:    'Editar Equipa',
                    settings: {
                        menu:        'concessions',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.teams' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/teams/:id/view',
                    name:     'management.concessions.teams.view',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/teams/view/index'),
                    nav:      false,
                    title:    'Visualizar Equipa',
                    settings: {
                        menu:        'concessions',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.teams' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== management » concessions » constructions » OE Elements ====================
                {
                    route:    'management/concessions/constructions/oe-elements',
                    name:     'management.concessions.constructions.oe-elements.index',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/constructions/index'),
                    nav:      true,
                    title:    'OE Elementos',
                    settings: {
                        menu:        'OE',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.oe' },
                            { title: 'page.breadcrumbs.elements' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/constructions/oe-elements/create',
                    name:     'management.concessions.constructions.oe-elements.create',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/constructions/create/index'),
                    nav:      false,
                    title:    'Criar OE Elemento',
                    settings: {
                        menu:        'OE',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.oe' },
                            { title: 'page.breadcrumbs.elements' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/constructions/oe-elements/:id/edit',
                    name:     'management.concessions.constructions.oe-elements.edit',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/constructions/edit/index'),
                    nav:      false,
                    title:    'Editar OE Elemento',
                    settings: {
                        menu:        'OE',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.oe' },
                            { title: 'page.breadcrumbs.elements' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/constructions/oe-elements/:id/view',
                    name:     'management.concessions.constructions.oe-elements.view',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/constructions/view/index'),
                    nav:      false,
                    title:    'Visualizar OE Elemento',
                    settings: {
                        menu:        'OE',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.oe' },
                            { title: 'page.breadcrumbs.elements' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== management » concessions » constructions » OE Eixos ====================
                {
                    route:    'management/concessions/constructions/oe-axis',
                    name:     'management.concessions.constructions.oe-axis.index',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/constructions/index'),
                    nav:      true,
                    title:    'OE Eixos',
                    settings: {
                        menu:        'OE',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.oe' },
                            { title: 'page.breadcrumbs.axis' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/constructions/oe-axis/create',
                    name:     'management.concessions.constructions.oe-axis.create',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/constructions/create/index'),
                    nav:      false,
                    title:    'Criar OE Eixo',
                    settings: {
                        menu:        'OE',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.oe' },
                            { title: 'page.breadcrumbs.axis' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/constructions/oe-axis/:id/edit',
                    name:     'management.concessions.constructions.oe-axis.edit',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/constructions/edit/index'),
                    nav:      false,
                    title:    'Editar OE Eixo',
                    settings: {
                        menu:        'OE',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.oe' },
                            { title: 'page.breadcrumbs.axis' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/constructions/oe-axis/:id/view',
                    name:     'management.concessions.constructions.oe-axis.view',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/constructions/view/index'),
                    nav:      false,
                    title:    'Visualizar OE Eixo',
                    settings: {
                        menu:        'OE',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.oe' },
                            { title: 'page.breadcrumbs.axis' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== management » concessions » constructions » OE Ramos ====================
                {
                    route:    'management/concessions/constructions/oe-branches',
                    name:     'management.concessions.constructions.oe-branches.index',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/constructions/index'),
                    nav:      true,
                    title:    'OE Ramos',
                    settings: {
                        menu:        'OE',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.oe' },
                            { title: 'page.breadcrumbs.branches' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/constructions/oe-branches/create',
                    name:     'management.concessions.constructions.oe-branches.create',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/constructions/create/index'),
                    nav:      false,
                    title:    'Criar OE Ramo',
                    settings: {
                        menu:        'OE',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.oe' },
                            { title: 'page.breadcrumbs.branches' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/constructions/oe-branches/:id/edit',
                    name:     'management.concessions.constructions.oe-branches.edit',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/constructions/edit/index'),
                    nav:      false,
                    title:    'Editar OE Ramo',
                    settings: {
                        menu:        'OE',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.oe' },
                            { title: 'page.breadcrumbs.branches' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/constructions/oe-branches/:id/view',
                    name:     'management.concessions.constructions.oe-branches.view',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/constructions/view/index'),
                    nav:      false,
                    title:    'Visualizar OE Ramo',
                    settings: {
                        menu:        'OE',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.oe' },
                            { title: 'page.breadcrumbs.branches' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== management » concessions » constructions » OE Bordos ====================
                {
                    route:    'management/concessions/constructions/oe-edges',
                    name:     'management.concessions.constructions.oe-edges.index',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/constructions/index'),
                    nav:      true,
                    title:    'OE Bordos',
                    settings: {
                        menu:        'OE',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.oe' },
                            { title: 'page.breadcrumbs.edges' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/constructions/oe-edges/create',
                    name:     'management.concessions.constructions.oe-edges.create',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/constructions/create/index'),
                    nav:      false,
                    title:    'Criar OE Bordo',
                    settings: {
                        menu:        'OE',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.oe' },
                            { title: 'page.breadcrumbs.edges' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/constructions/oe-edges/:id/edit',
                    name:     'management.concessions.constructions.oe-edges.edit',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/constructions/edit/index'),
                    nav:      false,
                    title:    'Editar OE Bordo',
                    settings: {
                        menu:        'OE',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.oe' },
                            { title: 'page.breadcrumbs.edges' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/constructions/oe-edges/:id/view',
                    name:     'management.concessions.constructions.oe-edges.view',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/constructions/view/index'),
                    nav:      false,
                    title:    'Visualizar OE Bordo',
                    settings: {
                        menu:        'OE',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.oe' },
                            { title: 'page.breadcrumbs.edges' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== management » concessions » constructions » OA Tipos ====================
                {
                    route:    'management/concessions/constructions/oa-types',
                    name:     'management.concessions.constructions.oa-types.index',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/constructions/index'),
                    nav:      true,
                    title:    'OA Tipos',
                    settings: {
                        menu:        'OA',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.oa' },
                            { title: 'page.breadcrumbs.types' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/constructions/oa-types/create',
                    name:     'management.concessions.constructions.oa-types.create',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/constructions/create/index'),
                    nav:      false,
                    title:    'Criar OA Tipo',
                    settings: {
                        menu:        'OA',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.oa' },
                            { title: 'page.breadcrumbs.types' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/constructions/oa-types/:id/edit',
                    name:     'management.concessions.constructions.oa-types.edit',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/constructions/edit/index'),
                    nav:      false,
                    title:    'Editar OA Tipo',
                    settings: {
                        menu:        'OA',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.oa' },
                            { title: 'page.breadcrumbs.types' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/constructions/oa-types/:id/view',
                    name:     'management.concessions.constructions.oa-types.view',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/constructions/view/index'),
                    nav:      false,
                    title:    'Visualizar OA Tipo',
                    settings: {
                        menu:        'OA',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.oa' },
                            { title: 'page.breadcrumbs.types' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== management » concessions » constructions » OA Elementos ====================
                {
                    route:    'management/concessions/constructions/oa-elements',
                    name:     'management.concessions.constructions.oa-elements.index',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/constructions/index'),
                    nav:      true,
                    title:    'OA Elementos',
                    settings: {
                        menu:        'OA',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.oa' },
                            { title: 'page.breadcrumbs.elements' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/constructions/oa-elements/create',
                    name:     'management.concessions.constructions.oa-elements.create',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/constructions/create/index'),
                    nav:      false,
                    title:    'Criar OA Elemento',
                    settings: {
                        menu:        'OA',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.oa' },
                            { title: 'page.breadcrumbs.elements' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/constructions/oa-elements/:id/edit',
                    name:     'management.concessions.constructions.oa-elements.edit',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/constructions/edit/index'),
                    nav:      false,
                    title:    'Editar OA Elemento',
                    settings: {
                        menu:        'OA',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.oa' },
                            { title: 'page.breadcrumbs.elements' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/constructions/oa-elements/:id/view',
                    name:     'management.concessions.constructions.oa-elements.view',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/constructions/view/index'),
                    nav:      false,
                    title:    'Visualizar OA Elemento',
                    settings: {
                        menu:        'OA',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.oa' },
                            { title: 'page.breadcrumbs.elements' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== management » concessions » constructions » OA Sub Elementos ====================
                {
                    route:    'management/concessions/constructions/oa-sub-elements',
                    name:     'management.concessions.constructions.oa-sub-elements.index',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/constructions/index'),
                    nav:      true,
                    title:    'OA Sub-Elementos',
                    settings: {
                        menu:        'OA',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.oa' },
                            { title: 'page.breadcrumbs.sub-elements' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/constructions/oa-sub-elements/create',
                    name:     'management.concessions.constructions.oa-sub-elements.create',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/constructions/create/index'),
                    nav:      false,
                    title:    'Criar OA Sub-Elemento',
                    settings: {
                        menu:        'OA',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.oa' },
                            { title: 'page.breadcrumbs.sub-elements' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/constructions/oa-sub-elements/:id/edit',
                    name:     'management.concessions.constructions.oa-sub-elements.edit',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/constructions/edit/index'),
                    nav:      false,
                    title:    'Editar OA Sub-Elemento',
                    settings: {
                        menu:        'OA',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.oa' },
                            { title: 'page.breadcrumbs.sub-elements' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/constructions/oa-sub-elements/:id/view',
                    name:     'management.concessions.constructions.oa-sub-elements.view',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/constructions/view/index'),
                    nav:      false,
                    title:    'Visualizar OA Sub-Elemento',
                    settings: {
                        menu:        'OA',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.oa' },
                            { title: 'page.breadcrumbs.sub-elements' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== management » concessions » constructions » OH Tipos ====================
                {
                    route:    'management/concessions/constructions/oh-types',
                    name:     'management.concessions.constructions.oh-types.index',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/constructions/index'),
                    nav:      true,
                    title:    'OH Tipos',
                    settings: {
                        menu:        'OH',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.oh' },
                            { title: 'page.breadcrumbs.types' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/constructions/oh-types/create',
                    name:     'management.concessions.constructions.oh-types.create',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/constructions/create/index'),
                    nav:      false,
                    title:    'Criar OH Tipo',
                    settings: {
                        menu:        'OH',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.oh' },
                            { title: 'page.breadcrumbs.types' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/constructions/oh-types/:id/edit',
                    name:     'management.concessions.constructions.oh-types.edit',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/constructions/edit/index'),
                    nav:      false,
                    title:    'Editar OH Tipo',
                    settings: {
                        menu:        'OH',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.oh' },
                            { title: 'page.breadcrumbs.types' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/constructions/oh-types/:id/view',
                    name:     'management.concessions.constructions.oh-types.view',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/constructions/view/index'),
                    nav:      false,
                    title:    'Visualizar OH Tipo',
                    settings: {
                        menu:        'OH',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.oh' },
                            { title: 'page.breadcrumbs.types' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== management » concessions » constructions » OH Elementos ====================
                {
                    route:    'management/concessions/constructions/oh-elements',
                    name:     'management.concessions.constructions.oh-elements.index',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/constructions/index'),
                    nav:      true,
                    title:    'OH Elementos',
                    settings: {
                        menu:        'OH',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.oh' },
                            { title: 'page.breadcrumbs.elements' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/constructions/oh-elements/create',
                    name:     'management.concessions.constructions.oh-elements.create',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/constructions/create/index'),
                    nav:      false,
                    title:    'Criar OH Elemento',
                    settings: {
                        menu:        'OH',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.oh' },
                            { title: 'page.breadcrumbs.elements' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/constructions/oh-elements/:id/edit',
                    name:     'management.concessions.constructions.oh-elements.edit',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/constructions/edit/index'),
                    nav:      false,
                    title:    'Editar OH Elemento',
                    settings: {
                        menu:        'OH',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.oh' },
                            { title: 'page.breadcrumbs.elements' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/constructions/oh-elements/:id/view',
                    name:     'management.concessions.constructions.oh-elements.view',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/constructions/view/index'),
                    nav:      false,
                    title:    'Visualizar OH Elemento',
                    settings: {
                        menu:        'OH',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.oh' },
                            { title: 'page.breadcrumbs.elements' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== management » concessions » constructions » OH Sub Elementos ====================
                {
                    route:    'management/concessions/constructions/oh-sub-elements',
                    name:     'management.concessions.constructions.oh-sub-elements.index',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/constructions/index'),
                    nav:      true,
                    title:    'OH Sub-Elementos',
                    settings: {
                        menu:        'OH',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.oh' },
                            { title: 'page.breadcrumbs.sub-elements' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/constructions/oh-sub-elements/create',
                    name:     'management.concessions.constructions.oh-sub-elements.create',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/constructions/create/index'),
                    nav:      false,
                    title:    'Criar OH Sub-Elemento',
                    settings: {
                        menu:        'OH',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.oh' },
                            { title: 'page.breadcrumbs.sub-elements' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/constructions/oh-sub-elements/:id/edit',
                    name:     'management.concessions.constructions.oh-sub-elements.edit',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/constructions/edit/index'),
                    nav:      false,
                    title:    'Editar OH Sub-Elemento',
                    settings: {
                        menu:        'OH',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.oh' },
                            { title: 'page.breadcrumbs.sub-elements' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/concessions/constructions/oh-sub-elements/:id/view',
                    name:     'management.concessions.constructions.oh-sub-elements.view',
                    moduleId: PLATFORM.moduleName('modules/management/concessions/constructions/view/index'),
                    nav:      false,
                    title:    'Visualizar OH Sub-Elemento',
                    settings: {
                        menu:        'OH',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concessions' },
                            { title: 'page.breadcrumbs.oh' },
                            { title: 'page.breadcrumbs.sub-elements' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== management » specifications » bulletin-fields ====================
                {
                    route:    'management/specifications/bulletin-fields',
                    name:     'management.specifications.bulletin-fields.index',
                    moduleId: PLATFORM.moduleName('modules/management/specifications/bulletin-fields/index'),
                    nav:      true,
                    title:    'Campos de Boletim de Ensaios',
                    settings: {
                        menu:        'specifications',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.specifications' },
                            { title: 'page.breadcrumbs.bulletin-fields' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'management/specifications/bulletin-fields/create',
                    name:     'management.specifications.bulletin-fields.create',
                    moduleId: PLATFORM.moduleName('modules/management/specifications/bulletin-fields/create/index'),
                    nav:      false,
                    title:    'Criar Campo de Boletim de Ensaios',
                    settings: {
                        menu:        'specifications',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.specifications' },
                            { title: 'page.breadcrumbs.bulletin-fields' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/specifications/bulletin-fields/:id/edit',
                    name:     'management.specifications.bulletin-fields.edit',
                    moduleId: PLATFORM.moduleName('modules/management/specifications/bulletin-fields/edit/index'),
                    nav:      false,
                    title:    'Editar Campo de Boletim de Ensaios',
                    settings: {
                        menu:        'specifications',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.specifications' },
                            { title: 'page.breadcrumbs.bulletin-fields' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/specifications/bulletin-fields/:id/view',
                    name:     'management.specifications.bulletin-fields.view',
                    moduleId: PLATFORM.moduleName('modules/management/specifications/bulletin-fields/view/index'),
                    nav:      false,
                    title:    'Visualizar Campo de Boletim de Ensaios',
                    settings: {
                        menu:        'specifications',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.specifications' },
                            { title: 'page.breadcrumbs.bulletin-fields' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== management » specifications » listings ====================
                {
                    route:    'management/specifications/listings',
                    name:     'management.specifications.listings.index',
                    moduleId: PLATFORM.moduleName('modules/management/specifications/listings/index'),
                    nav:      true,
                    title:    'Listas',
                    settings: {
                        menu:        'specifications',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.specifications' },
                            { title: 'page.breadcrumbs.listings' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'management/specifications/listings/create',
                    name:     'management.specifications.listings.create',
                    moduleId: PLATFORM.moduleName('modules/management/specifications/listings/create/index'),
                    nav:      false,
                    title:    'Criar Lista',
                    settings: {
                        menu:        'specifications',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.specifications' },
                            { title: 'page.breadcrumbs.listings' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/specifications/listings/:id/edit',
                    name:     'management.specifications.listings.edit',
                    moduleId: PLATFORM.moduleName('modules/management/specifications/listings/edit/index'),
                    nav:      false,
                    title:    'Editar Lista',
                    settings: {
                        menu:        'specifications',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.specifications' },
                            { title: 'page.breadcrumbs.listings' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/specifications/listings/:id/view',
                    name:     'management.specifications.listings.view',
                    moduleId: PLATFORM.moduleName('modules/management/specifications/listings/view/index'),
                    nav:      false,
                    title:    'Visualizar Lista',
                    settings: {
                        menu:        'specifications',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.specifications' },
                            { title: 'page.breadcrumbs.listings' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== management » specifications » bulletins ====================
                {
                    route:    'management/specifications/bulletins',
                    name:     'management.specifications.bulletins.index',
                    moduleId: PLATFORM.moduleName('modules/management/specifications/bulletins/index'),
                    nav:      true,
                    title:    'Boletim',
                    settings: {
                        menu:        'specifications',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.specifications' },
                            { title: 'page.breadcrumbs.bulletins' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'management/specifications/bulletins/create',
                    name:     'management.specifications.bulletins.create',
                    moduleId: PLATFORM.moduleName('modules/management/specifications/bulletins/create/index'),
                    nav:      false,
                    title:    'Criar Boletim',
                    settings: {
                        menu:        'specifications',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.specifications' },
                            { title: 'page.breadcrumbs.bulletins' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/specifications/bulletins/:id/edit',
                    name:     'management.specifications.bulletins.edit',
                    moduleId: PLATFORM.moduleName('modules/management/specifications/bulletins/edit/index'),
                    nav:      false,
                    title:    'Editar Boletim',
                    settings: {
                        menu:        'specifications',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.specifications' },
                            { title: 'page.breadcrumbs.bulletins' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/specifications/bulletins/:id/view',
                    name:     'management.specifications.bulletins.view',
                    moduleId: PLATFORM.moduleName('modules/management/specifications/bulletins/view/index'),
                    nav:      false,
                    title:    'Visualizar Boletim',
                    settings: {
                        menu:        'specifications',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.specifications' },
                            { title: 'page.breadcrumbs.bulletins' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== management » concrete » manage fields ====================
                {
                    route:    'management/concrete/manage-fields',
                    name:     'management.concrete.manage-fields.index',
                    moduleId: PLATFORM.moduleName('modules/management/concrete/manage-fields/index'),
                    nav:      true,
                    title:    'Gestão de campos',
                    settings: {
                        menu:        'concrete',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concrete' },
                            { title: 'page.breadcrumbs.manage-fields' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'management/concrete/manage-fields/create',
                    name:     'management.concrete.manage-fields.create',
                    moduleId: PLATFORM.moduleName('modules/management/concrete/manage-fields/create/index'),
                    nav:      false,
                    title:    'Criar Campo',
                    settings: {
                        menu:        'concrete',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concrete' },
                            { title: 'page.breadcrumbs.manage-fields' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/concrete/manage-fields/:id/edit',
                    name:     'management.concrete.manage-fields.edit',
                    moduleId: PLATFORM.moduleName('modules/management/concrete/manage-fields/edit/index'),
                    nav:      false,
                    title:    'Editar Campo',
                    settings: {
                        menu:        'concrete',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concrete' },
                            { title: 'page.breadcrumbs.manage-fields' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/concrete/manage-fields/:id/view',
                    name:     'management.concrete.manage-fields.view',
                    moduleId: PLATFORM.moduleName('modules/management/concrete/manage-fields/view/index'),
                    nav:      false,
                    title:    'Visualizar Campo',
                    settings: {
                        menu:        'concrete',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concrete' },
                            { title: 'page.breadcrumbs.manage-fields' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== management » concrete » manage studies ====================
                {
                    route:    'management/concrete/manage-studies',
                    name:     'management.concrete.manage-studies.index',
                    moduleId: PLATFORM.moduleName('modules/management/concrete/manage-studies/index'),
                    nav:      true,
                    title:    'Gestão de Estudos',
                    settings: {
                        menu:        'concrete',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concrete' },
                            { title: 'page.breadcrumbs.manage-studies' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'management/concrete/manage-studies/create',
                    name:     'management.concrete.manage-studies.create',
                    moduleId: PLATFORM.moduleName('modules/management/concrete/manage-studies/create/index'),
                    nav:      false,
                    title:    'Criar Estudo',
                    settings: {
                        menu:        'concrete',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concrete' },
                            { title: 'page.breadcrumbs.manage-studies' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/concrete/manage-studies/:id/edit',
                    name:     'management.concrete.manage-studies.edit',
                    moduleId: PLATFORM.moduleName('modules/management/concrete/manage-studies/edit/index'),
                    nav:      false,
                    title:    'Editar Estudo',
                    settings: {
                        menu:        'concrete',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concrete' },
                            { title: 'page.breadcrumbs.manage-studies' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/concrete/manage-studies/:id/view',
                    name:     'management.concrete.manage-studies.view',
                    moduleId: PLATFORM.moduleName('modules/management/concrete/manage-studies/view/index'),
                    nav:      false,
                    title:    'Visualizar Estudo',
                    settings: {
                        menu:        'concrete',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.concrete' },
                            { title: 'page.breadcrumbs.manage-studies' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== management » aggregates » aggregates-applications ====================
                {
                    route:    'management/aggregates/aggregates-applications',
                    name:     'management.aggregates.aggregates-applications.index',
                    moduleId: PLATFORM.moduleName('modules/management/aggregates/aggregates-applications/index'),
                    nav:      true,
                    title:    'Aplicação',
                    settings: {
                        menu:        'aggregates',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.aggregates' },
                            { title: 'page.breadcrumbs.aggregates-application' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'management/aggregates/aggregates-applications/create',
                    name:     'management.aggregates.aggregates-applications.create',
                    moduleId: PLATFORM.moduleName('modules/management/aggregates/aggregates-applications/create/index'),
                    nav:      false,
                    title:    'Criar Aplicação',
                    settings: {
                        menu:        'aggregates',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.aggregates' },
                            { title: 'page.breadcrumbs.aggregates-application' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/aggregates/aggregates-applications/:id/edit',
                    name:     'management.aggregates.aggregates-applications.edit',
                    moduleId: PLATFORM.moduleName('modules/management/aggregates/aggregates-applications/edit/index'),
                    nav:      false,
                    title:    'Editar Aplicação',
                    settings: {
                        menu:        'aggregates',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.aggregates' },
                            { title: 'page.breadcrumbs.aggregates-application' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/aggregates/aggregates-applications/:id/view',
                    name:     'management.aggregates.aggregates-applications.view',
                    moduleId: PLATFORM.moduleName('modules/management/aggregates/aggregates-applications/view/index'),
                    nav:      false,
                    title:    'Visualizar Aplicação',
                    settings: {
                        menu:        'aggregates',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.aggregates' },
                            { title: 'page.breadcrumbs.aggregates-application' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== management » aggregates » association-applications ====================
                {
                    route:    'management/aggregates/association-applications',
                    name:     'management.aggregates.association-applications.index',
                    moduleId: PLATFORM.moduleName('modules/management/aggregates/association-applications/index'),
                    nav:      true,
                    title:    'Associações a aplicações',
                    settings: {
                        menu:        'aggregates',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.aggregates' },
                            { title: 'page.breadcrumbs.association-application' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'management/aggregates/association-applications/create',
                    name:     'management.aggregates.association-applications.create',
                    moduleId: PLATFORM.moduleName('modules/management/aggregates/association-applications/create/index'),
                    nav:      false,
                    title:    'Criar Associação a Aplicação',
                    settings: {
                        menu:        'aggregates',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.aggregates' },
                            { title: 'page.breadcrumbs.association-application' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/aggregates/association-applications/:id/edit',
                    name:     'management.aggregates.association-applications.edit',
                    moduleId: PLATFORM.moduleName('modules/management/aggregates/association-applications/edit/index'),
                    nav:      false,
                    title:    'Editar Associação a Aplicação',
                    settings: {
                        menu:        'aggregates',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.aggregates' },
                            { title: 'page.breadcrumbs.association-application' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/aggregates/association-applications/:id/view',
                    name:     'management.aggregates.association-applications.view',
                    moduleId: PLATFORM.moduleName('modules/management/aggregates/association-applications/view/index'),
                    nav:      false,
                    title:    'Visualizar Associação a Aplicação',
                    settings: {
                        menu:        'aggregates',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.aggregates' },
                            { title: 'page.breadcrumbs.association-application' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== management » aggregates » usages ====================
                {
                    route:    'management/aggregates/usages',
                    name:     'management.aggregates.usages.index',
                    moduleId: PLATFORM.moduleName('modules/management/aggregates/usages/index'),
                    nav:      true,
                    title:    'Utilização em',
                    settings: {
                        menu:        'aggregates',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.aggregates' },
                            { title: 'page.breadcrumbs.usage' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'management/aggregates/usages/create',
                    name:     'management.aggregates.usages.create',
                    moduleId: PLATFORM.moduleName('modules/management/aggregates/usages/create/index'),
                    nav:      false,
                    title:    'Criar Utilização',
                    settings: {
                        menu:        'aggregates',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.aggregates' },
                            { title: 'page.breadcrumbs.usage' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/aggregates/usages/:id/edit',
                    name:     'management.aggregates.usages.edit',
                    moduleId: PLATFORM.moduleName('modules/management/aggregates/usages/edit/index'),
                    nav:      false,
                    title:    'Editar Utilização',
                    settings: {
                        menu:        'aggregates',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.aggregates' },
                            { title: 'page.breadcrumbs.usage' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/aggregates/usages/:id/view',
                    name:     'management.aggregates.usages.view',
                    moduleId: PLATFORM.moduleName('modules/management/aggregates/usages/view/index'),
                    nav:      false,
                    title:    'Visualizar Utilização',
                    settings: {
                        menu:        'aggregates',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.aggregates' },
                            { title: 'page.breadcrumbs.usage' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== management » aggregates » test-sample-types ====================
                {
                    route:    'management/aggregates/test-sample-types',
                    name:     'management.aggregates.test-sample-types.index',
                    moduleId: PLATFORM.moduleName('modules/management/aggregates/test-sample-types/index'),
                    nav:      true,
                    title:    'Tipos de Amostras a Ensaiar',
                    settings: {
                        menu:        'aggregates',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.aggregates' },
                            { title: 'page.breadcrumbs.test-sample-type' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'management/aggregates/test-sample-types/create',
                    name:     'management.aggregates.test-sample-types.create',
                    moduleId: PLATFORM.moduleName('modules/management/aggregates/test-sample-types/create/index'),
                    nav:      false,
                    title:    'Criar Tipo de Amostras a Ensaiar',
                    settings: {
                        menu:        'aggregates',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.aggregates' },
                            { title: 'page.breadcrumbs.test-sample-type' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/aggregates/test-sample-types/:id/edit',
                    name:     'management.aggregates.test-sample-types.edit',
                    moduleId: PLATFORM.moduleName('modules/management/aggregates/test-sample-types/edit/index'),
                    nav:      false,
                    title:    'Editar Tipo de Amostras a Ensaiar',
                    settings: {
                        menu:        'aggregates',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.aggregates' },
                            { title: 'page.breadcrumbs.test-sample-type' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/aggregates/test-sample-types/:id/view',
                    name:     'management.aggregates.test-sample-types.view',
                    moduleId: PLATFORM.moduleName('modules/management/aggregates/test-sample-types/view/index'),
                    nav:      false,
                    title:    'Visualizar Tipo de Amostras a Ensaiar',
                    settings: {
                        menu:        'aggregates',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.aggregates' },
                            { title: 'page.breadcrumbs.test-sample-type' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== management » bituminous-mixtures » properties ====================
                {
                    route:    'management/bituminous-mixtures/properties',
                    name:     'management.bituminous-mixtures.properties.index',
                    moduleId: PLATFORM.moduleName('modules/management/bituminous-mixtures/properties/index'),
                    nav:      true,
                    title:    'Propriedades',
                    settings: {
                        menu:        'bituminous-mixtures',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.property' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'management/bituminous-mixtures/properties/create',
                    name:     'management.bituminous-mixtures.properties.create',
                    moduleId: PLATFORM.moduleName('modules/management/bituminous-mixtures/properties/create/index'),
                    nav:      false,
                    title:    'Criar Propriedade',
                    settings: {
                        menu:        'bituminous-mixtures',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.property' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/bituminous-mixtures/properties/:id/edit',
                    name:     'management.bituminous-mixtures.properties.edit',
                    moduleId: PLATFORM.moduleName('modules/management/bituminous-mixtures/properties/edit/index'),
                    nav:      false,
                    title:    'Editar Propriedade',
                    settings: {
                        menu:        'bituminous-mixtures',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.property' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/bituminous-mixtures/properties/:id/view',
                    name:     'management.bituminous-mixtures.properties.view',
                    moduleId: PLATFORM.moduleName('modules/management/bituminous-mixtures/properties/view/index'),
                    nav:      false,
                    title:    'Visualizar Propriedade',
                    settings: {
                        menu:        'bituminous-mixtures',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.property' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== management » bituminous-mixtures » lanes ====================
                {
                    route:    'management/bituminous-mixtures/lanes',
                    name:     'management.bituminous-mixtures.lanes.index',
                    moduleId: PLATFORM.moduleName('modules/management/bituminous-mixtures/lanes/index'),
                    nav:      true,
                    title:    'Vias',
                    settings: {
                        menu:        'bituminous-mixtures',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.lane' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'management/bituminous-mixtures/lanes/create',
                    name:     'management.bituminous-mixtures.lanes.create',
                    moduleId: PLATFORM.moduleName('modules/management/bituminous-mixtures/lanes/create/index'),
                    nav:      false,
                    title:    'Criar Via',
                    settings: {
                        menu:        'bituminous-mixtures',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.lane' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/bituminous-mixtures/lanes/:id/edit',
                    name:     'management.bituminous-mixtures.lanes.edit',
                    moduleId: PLATFORM.moduleName('modules/management/bituminous-mixtures/lanes/edit/index'),
                    nav:      false,
                    title:    'Editar Via',
                    settings: {
                        menu:        'bituminous-mixtures',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.lane' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/bituminous-mixtures/lanes/:id/view',
                    name:     'management.bituminous-mixtures.lanes.view',
                    moduleId: PLATFORM.moduleName('modules/management/bituminous-mixtures/lanes/view/index'),
                    nav:      false,
                    title:    'Visualizar Via',
                    settings: {
                        menu:        'bituminous-mixtures',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.sample-collection' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== management » bituminous-mixtures » study-types ====================
                {
                    route:    'management/bituminous-mixtures/study-types',
                    name:     'management.bituminous-mixtures.study-types.index',
                    moduleId: PLATFORM.moduleName('modules/management/bituminous-mixtures/study-types/index'),
                    nav:      true,
                    title:    'Tipos de Estudo',
                    settings: {
                        menu:        'bituminous-mixtures',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.study-type' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'management/bituminous-mixtures/study-types/create',
                    name:     'management.bituminous-mixtures.study-types.create',
                    moduleId: PLATFORM.moduleName('modules/management/bituminous-mixtures/study-types/create/index'),
                    nav:      false,
                    title:    'Criar Tipo de Estudo',
                    settings: {
                        menu:        'bituminous-mixtures',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.study-type' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/bituminous-mixtures/study-types/:id/edit',
                    name:     'management.bituminous-mixtures.study-types.edit',
                    moduleId: PLATFORM.moduleName('modules/management/bituminous-mixtures/study-types/edit/index'),
                    nav:      false,
                    title:    'Editar Tipo de Estudo',
                    settings: {
                        menu:        'bituminous-mixtures',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.study-type' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/bituminous-mixtures/study-types/:id/view',
                    name:     'management.bituminous-mixtures.study-types.view',
                    moduleId: PLATFORM.moduleName('modules/management/bituminous-mixtures/study-types/view/index'),
                    nav:      false,
                    title:    'Visualizar Tipo de Estudo',
                    settings: {
                        menu:        'bituminous-mixtures',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.study-type' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== management » bituminous-mixtures » composition-data ====================
                {
                    route:    'management/bituminous-mixtures/composition-data',
                    name:     'management.bituminous-mixtures.composition-data.index',
                    moduleId: PLATFORM.moduleName('modules/management/bituminous-mixtures/composition-data/index'),
                    nav:      true,
                    title:    'Dados de Composição',
                    settings: {
                        menu:        'bituminous-mixtures',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.composition-data' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'management/bituminous-mixtures/composition-data/create',
                    name:     'management.bituminous-mixtures.composition-data.create',
                    moduleId: PLATFORM.moduleName('modules/management/bituminous-mixtures/composition-data/create/index'),
                    nav:      false,
                    title:    'Criar Dado de Composição',
                    settings: {
                        menu:        'bituminous-mixtures',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.composition-data' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/bituminous-mixtures/composition-data/:id/edit',
                    name:     'management.bituminous-mixtures.composition-data.edit',
                    moduleId: PLATFORM.moduleName('modules/management/bituminous-mixtures/composition-data/edit/index'),
                    nav:      false,
                    title:    'Editar Dado de Composição',
                    settings: {
                        menu:        'bituminous-mixtures',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.composition-data' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/bituminous-mixtures/composition-data/:id/view',
                    name:     'management.bituminous-mixtures.composition-data.view',
                    moduleId: PLATFORM.moduleName('modules/management/bituminous-mixtures/composition-data/view/index'),
                    nav:      false,
                    title:    'Visualizar Dado de Composição',
                    settings: {
                        menu:        'bituminous-mixtures',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.composition-data' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== management » bituminous-mixtures » layers ====================
                {
                    route:    'management/bituminous-mixtures/layers',
                    name:     'management.bituminous-mixtures.layers.index',
                    moduleId: PLATFORM.moduleName('modules/management/bituminous-mixtures/layers/index'),
                    nav:      true,
                    title:    'Camadas',
                    settings: {
                        menu:        'bituminous-mixtures',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.layers' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'management/bituminous-mixtures/layers/create',
                    name:     'management.bituminous-mixtures.layers.create',
                    moduleId: PLATFORM.moduleName('modules/management/bituminous-mixtures/layers/create/index'),
                    nav:      false,
                    title:    'Criar Camada',
                    settings: {
                        menu:        'bituminous-mixtures',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.layers' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/bituminous-mixtures/layers/:id/edit',
                    name:     'management.bituminous-mixtures.layers.edit',
                    moduleId: PLATFORM.moduleName('modules/management/bituminous-mixtures/layers/edit/index'),
                    nav:      false,
                    title:    'Editar Camada',
                    settings: {
                        menu:        'bituminous-mixtures',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.layers' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/bituminous-mixtures/layers/:id/view',
                    name:     'management.bituminous-mixtures.layers.view',
                    moduleId: PLATFORM.moduleName('modules/management/bituminous-mixtures/layers/view/index'),
                    nav:      false,
                    title:    'Visualizar Camada',
                    settings: {
                        menu:        'bituminous-mixtures',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.layers' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== management » bituminous-mixtures » bituminous-applications ====================
                {
                    route:    'management/bituminous-mixtures/bituminous-applications',
                    name:     'management.bituminous-mixtures.bituminous-applications.index',
                    moduleId: PLATFORM.moduleName('modules/management/bituminous-mixtures/bituminous-applications/index'),
                    nav:      true,
                    title:    'Aplicação',
                    settings: {
                        menu:        'bituminous-mixtures',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.application' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'management/bituminous-mixtures/bituminous-applications/create',
                    name:     'management.bituminous-mixtures.bituminous-applications.create',
                    moduleId: PLATFORM.moduleName('modules/management/bituminous-mixtures/bituminous-applications/create/index'),
                    nav:      false,
                    title:    'Criar Aplicação',
                    settings: {
                        menu:        'bituminous-mixtures',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.application' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/bituminous-mixtures/bituminous-applications/:id/edit',
                    name:     'management.bituminous-mixtures.bituminous-applications.edit',
                    moduleId: PLATFORM.moduleName('modules/management/bituminous-mixtures/bituminous-applications/edit/index'),
                    nav:      false,
                    title:    'Editar Aplicação',
                    settings: {
                        menu:        'bituminous-mixtures',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.application' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/bituminous-mixtures/bituminous-applications/:id/view',
                    name:     'management.bituminous-mixtures.bituminous-applications.view',
                    moduleId: PLATFORM.moduleName('modules/management/bituminous-mixtures/bituminous-applications/view/index'),
                    nav:      false,
                    title:    'Visualizar Aplicação',
                    settings: {
                        menu:        'bituminous-mixtures',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.application' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== management » bituminous-mixtures » association-specifications ====================
                {
                    route:    'management/bituminous-mixtures/association-specifications',
                    name:     'management.bituminous-mixtures.association-specifications.index',
                    moduleId: PLATFORM.moduleName('modules/management/bituminous-mixtures/association-specifications/index'),
                    nav:      true,
                    title:    'Associações de CE',
                    settings: {
                        menu:        'bituminous-mixtures',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.association-specification' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'management/bituminous-mixtures/association-specifications/create',
                    name:     'management.bituminous-mixtures.association-specifications.create',
                    moduleId: PLATFORM.moduleName('modules/management/bituminous-mixtures/association-specifications/create/index'),
                    nav:      false,
                    title:    'Criar Associação de CE',
                    settings: {
                        menu:        'bituminous-mixtures',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.association-specification' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/bituminous-mixtures/association-specifications/:id/edit',
                    name:     'management.bituminous-mixtures.association-specifications.edit',
                    moduleId: PLATFORM.moduleName('modules/management/bituminous-mixtures/association-specifications/edit/index'),
                    nav:      false,
                    title:    'Editar Associação de CE',
                    settings: {
                        menu:        'bituminous-mixtures',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.association-specification' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/bituminous-mixtures/association-specifications/:id/view',
                    name:     'management.bituminous-mixtures.association-specifications.view',
                    moduleId: PLATFORM.moduleName('modules/management/bituminous-mixtures/association-specifications/view/index'),
                    nav:      false,
                    title:    'Visualizar Associação de CE',
                    settings: {
                        menu:        'bituminous-mixtures',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.association-specification' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== management » dynamic-fields » material-types ====================
                {
                    route:    'management/dynamic-fields/material-types',
                    name:     'management.dynamic-fields.material-types.index',
                    moduleId: PLATFORM.moduleName('modules/management/dynamic-fields/material-types/index'),
                    nav:      true,
                    title:    'Gestão de tipos de materiais',
                    settings: {
                        menu:        'dynamic-fields',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.dynamic-fields' },
                            { title: 'page.breadcrumbs.material-types' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'management/dynamic-fields/material-types/create',
                    name:     'management.dynamic-fields.material-types.create',
                    moduleId: PLATFORM.moduleName('modules/management/dynamic-fields/material-types/create/index'),
                    nav:      false,
                    title:    'Criar Tipo de Material',
                    settings: {
                        menu:        'dynamic-fields',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.dynamic-fields' },
                            { title: 'page.breadcrumbs.material-types' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/dynamic-fields/material-types/:id/edit',
                    name:     'management.dynamic-fields.material-types.edit',
                    moduleId: PLATFORM.moduleName('modules/management/dynamic-fields/material-types/edit/index'),
                    nav:      false,
                    title:    'Editar Tipo de Material',
                    settings: {
                        menu:        'dynamic-fields',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.dynamic-fields' },
                            { title: 'page.breadcrumbs.material-types' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/dynamic-fields/material-types/:id/view',
                    name:     'management.dynamic-fields.material-types.view',
                    moduleId: PLATFORM.moduleName('modules/management/dynamic-fields/material-types/view/index'),
                    nav:      false,
                    title:    'Visualizar Tipo de Material',
                    settings: {
                        menu:        'dynamic-fields',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.dynamic-fields' },
                            { title: 'page.breadcrumbs.material-types' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== management » dynamic-fields » material-types ====================
                {
                    route:    'management/dynamic-fields/part-zone-applications',
                    name:     'management.dynamic-fields.part-zone-applications.index',
                    moduleId: PLATFORM.moduleName('modules/management/dynamic-fields/part-zone-applications/index'),
                    nav:      true,
                    title:    'Gestão de Parte/Zona/Aplicação',
                    settings: {
                        menu:        'dynamic-fields',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.dynamic-fields' },
                            { title: 'page.breadcrumbs.part-zone-applications' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'management/dynamic-fields/part-zone-applications/create',
                    name:     'management.dynamic-fields.part-zone-applications.create',
                    moduleId: PLATFORM.moduleName('modules/management/dynamic-fields/part-zone-applications/create/index'),
                    nav:      false,
                    title:    'Criar Parte/Zona/Aplicação',
                    settings: {
                        menu:        'dynamic-fields',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.dynamic-fields' },
                            { title: 'page.breadcrumbs.part-zone-applications' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/dynamic-fields/part-zone-applications/:id/edit',
                    name:     'management.dynamic-fields.part-zone-applications.edit',
                    moduleId: PLATFORM.moduleName('modules/management/dynamic-fields/part-zone-applications/edit/index'),
                    nav:      false,
                    title:    'Editar Parte/Zona/Aplicação',
                    settings: {
                        menu:        'dynamic-fields',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.dynamic-fields' },
                            { title: 'page.breadcrumbs.part-zone-applications' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/dynamic-fields/part-zone-applications/:id/view',
                    name:     'management.dynamic-fields.part-zone-applications.view',
                    moduleId: PLATFORM.moduleName('modules/management/dynamic-fields/part-zone-applications/view/index'),
                    nav:      false,
                    title:    'Visualizar Parte/Zona/Aplicação',
                    settings: {
                        menu:        'dynamic-fields',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.dynamic-fields' },
                            { title: 'page.breadcrumbs.part-zone-applications' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== management » dynamic-fields » manage-layers ====================
                {
                    route:    'management/dynamic-fields/manage-layers',
                    name:     'management.dynamic-fields.manage-layers.index',
                    moduleId: PLATFORM.moduleName('modules/management/dynamic-fields/manage-layers/index'),
                    nav:      true,
                    title:    'Gestão de Camadas (SRA)',
                    settings: {
                        menu:        'dynamic-fields',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.dynamic-fields' },
                            { title: 'page.breadcrumbs.manage-layers' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'management/dynamic-fields/manage-layers/create',
                    name:     'management.dynamic-fields.manage-layers.create',
                    moduleId: PLATFORM.moduleName('modules/management/dynamic-fields/manage-layers/create/index'),
                    nav:      false,
                    title:    'Criar Camada (SRA)',
                    settings: {
                        menu:        'dynamic-fields',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.dynamic-fields' },
                            { title: 'page.breadcrumbs.manage-layers' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/dynamic-fields/manage-layers/:id/edit',
                    name:     'management.dynamic-fields.manage-layers.edit',
                    moduleId: PLATFORM.moduleName('modules/management/dynamic-fields/manage-layers/edit/index'),
                    nav:      false,
                    title:    'Editar Camada (SRA)',
                    settings: {
                        menu:        'dynamic-fields',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.dynamic-fields' },
                            { title: 'page.breadcrumbs.manage-layers' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/dynamic-fields/manage-layers/:id/view',
                    name:     'management.dynamic-fields.manage-layers.view',
                    moduleId: PLATFORM.moduleName('modules/management/dynamic-fields/manage-layers/view/index'),
                    nav:      false,
                    title:    'Visualizar Camada (SRA)',
                    settings: {
                        menu:        'dynamic-fields',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.dynamic-fields' },
                            { title: 'page.breadcrumbs.manage-layers' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== management » dynamic-fields » performed-tests ====================
                {
                    route:    'management/dynamic-fields/performed-tests',
                    name:     'management.dynamic-fields.performed-tests.index',
                    moduleId: PLATFORM.moduleName('modules/management/dynamic-fields/performed-tests/index'),
                    nav:      true,
                    title:    'Gestão de Ensaios Realizados',
                    settings: {
                        menu:        'dynamic-fields',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.dynamic-fields' },
                            { title: 'page.breadcrumbs.performed-tests' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'management/dynamic-fields/performed-tests/create',
                    name:     'management.dynamic-fields.performed-tests.create',
                    moduleId: PLATFORM.moduleName('modules/management/dynamic-fields/performed-tests/create/index'),
                    nav:      false,
                    title:    'Criar Ensaio Realizado em',
                    settings: {
                        menu:        'dynamic-fields',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.dynamic-fields' },
                            { title: 'page.breadcrumbs.performed-tests' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/dynamic-fields/performed-tests/:id/edit',
                    name:     'management.dynamic-fields.performed-tests.edit',
                    moduleId: PLATFORM.moduleName('modules/management/dynamic-fields/performed-tests/edit/index'),
                    nav:      false,
                    title:    'Editar Ensaio Realizado em',
                    settings: {
                        menu:        'dynamic-fields',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.dynamic-fields' },
                            { title: 'page.breadcrumbs.performed-tests' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/dynamic-fields/performed-tests/:id/view',
                    name:     'management.dynamic-fields.performed-tests.view',
                    moduleId: PLATFORM.moduleName('modules/management/dynamic-fields/performed-tests/view/index'),
                    nav:      false,
                    title:    'Visualizar Ensaio Realizado em',
                    settings: {
                        menu:        'dynamic-fields',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.dynamic-fields' },
                            { title: 'page.breadcrumbs.performed-tests' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== management » dynamic-fields » sample-collections ====================
                {
                    route:    'management/dynamic-fields/sample-collections',
                    name:     'management.dynamic-fields.sample-collections.index',
                    moduleId: PLATFORM.moduleName('modules/management/dynamic-fields/sample-collections/index'),
                    nav:      true,
                    title:    'Colheitas da Amostra',
                    settings: {
                        menu:        'dynamic-fields',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.dynamic-fields' },
                            { title: 'page.breadcrumbs.sample-collection' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'management/dynamic-fields/sample-collections/create',
                    name:     'management.dynamic-fields.sample-collections.create',
                    moduleId: PLATFORM.moduleName('modules/management/dynamic-fields/sample-collections/create/index'),
                    nav:      false,
                    title:    'Criar Colheita da Amostra',
                    settings: {
                        menu:        'dynamic-fields',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.dynamic-fields' },
                            { title: 'page.breadcrumbs.sample-collection' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/dynamic-fields/sample-collections/:id/edit',
                    name:     'management.dynamic-fields.sample-collections.edit',
                    moduleId: PLATFORM.moduleName('modules/management/dynamic-fields/sample-collections/edit/index'),
                    nav:      false,
                    title:    'Editar Colheita da Amostra',
                    settings: {
                        menu:        'dynamic-fields',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.dynamic-fields' },
                            { title: 'page.breadcrumbs.sample-collection' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/dynamic-fields/sample-collections/:id/view',
                    name:     'management.dynamic-fields.sample-collections.view',
                    moduleId: PLATFORM.moduleName('modules/management/dynamic-fields/sample-collections/view/index'),
                    nav:      false,
                    title:    'Visualizar Colheita da Amostra',
                    settings: {
                        menu:        'dynamic-fields',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.dynamic-fields' },
                            { title: 'page.breadcrumbs.sample-collection' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== management » dynamic-fields » association-sectors ====================
                {
                    route:    'management/dynamic-fields/association-sectors/:category',
                    name:     'management.dynamic-fields.association-sectors.index',
                    moduleId: PLATFORM.moduleName('modules/management/dynamic-fields/association-sectors/index'),
                    nav:      true,
                    href:     'management/dynamic-fields/association-sectors/lab', // Default href
                    title:    'Associação ao Sector',
                    settings: {
                        menu:        'dynamic-fields',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.dynamic-fields' },
                            { title: 'page.breadcrumbs.sample-collection' },
                            { title: 'page.breadcrumbs.association-sectors' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'management/dynamic-fields/association-sectors/:category/:id/edit',
                    name:     'management.dynamic-fields.association-sectors.edit',
                    moduleId: PLATFORM.moduleName('modules/management/dynamic-fields/association-sectors/edit/index'),
                    nav:      false,
                    title:    'Editar Associação ao Sector',
                    settings: {
                        menu:        'dynamic-fields',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.dynamic-fields' },
                            { title: 'page.breadcrumbs.association-sectors' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },

                // ==================== management » alert-messages ====================
                {
                    route:    'management/alert-messages',
                    name:     'management.alert-messages.index',
                    moduleId: PLATFORM.moduleName('modules/management/alert-messages/index'),
                    nav:      true,
                    title:    'Mensagens de Alerta',
                    settings: {
                        menu:        'bituminous-mixtures',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.alert-messages' },
                            { title: 'page.breadcrumbs.alert-message' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'management/alert-messages/create',
                    name:     'management.alert-messages.create',
                    moduleId: PLATFORM.moduleName('modules/management/alert-messages/create/index'),
                    nav:      false,
                    title:    'Criar Mensagem de Alerta',
                    settings: {
                        menu:        'alert-messages',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.alert-messages' },
                            { title: 'page.breadcrumbs.alert-message' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/alert-messages/:id/edit',
                    name:     'management.alert-messages.edit',
                    moduleId: PLATFORM.moduleName('modules/management/alert-messages/edit/index'),
                    nav:      false,
                    title:    'Editar Mensagem de Alerta',
                    settings: {
                        menu:        'alert-messages',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.alert-messages' },
                            { title: 'page.breadcrumbs.alert-message' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/alert-messages/:id/view',
                    name:     'management.alert-messages.view',
                    moduleId: PLATFORM.moduleName('modules/management/alert-messages/view/index'),
                    nav:      false,
                    title:    'Visualizar Mensagem de Alerta',
                    settings: {
                        menu:        'alert-messages',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.alert-messages' },
                            { title: 'page.breadcrumbs.alert-message' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== management » report-headers ====================
                {
                    route:    'management/report-categories',
                    name:     'management.report-categories',
                    moduleId: PLATFORM.moduleName('modules/management/report-headers/categories'),
                    nav:      true,
                    title:    'Categorias - Cabeçalhos de Relatórios',
                    settings: {
                        menu:        'report-headers',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.report-headers' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'management/report-categories/:categoryId/',
                    name:     'management.report-categories.index',
                    moduleId: PLATFORM.moduleName('modules/management/report-headers/index'),
                    nav:      false,
                    title:    'Cabeçalhos de Relatórios',
                    settings: {
                        menu:        'report-headers',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.report-headers' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'management/report-headers/create',
                    name:     'management.report-headers.create',
                    moduleId: PLATFORM.moduleName('modules/management/report-headers/create/index'),
                    nav:      false,
                    title:    'Criar Cabeçalho de Relatórios',
                    settings: {
                        menu:        'report-headers',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.report-headers' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/report-categories/:id/edit',
                    name:     'management.report-headers.edit',
                    moduleId: PLATFORM.moduleName('modules/management/report-headers/edit/index'),
                    nav:      false,
                    title:    'Editar Cabeçalho de Relatórios',
                    settings: {
                        menu:        'report-headers',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.report-headers' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/report-headers/:id/view',
                    name:     'management.report-headers.view',
                    moduleId: PLATFORM.moduleName('modules/management/report-headers/view/index'),
                    nav:      false,
                    title:    'Visualizar Cabeçalho de Relatórios',
                    settings: {
                        menu:        'report-headers',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.report-headers' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== management » processing-errors-listing ====================
                {
                    route:    'management/processing-errors',
                    name:     'management.processing-errors',
                    moduleId: PLATFORM.moduleName('modules/management/processing-errors/index'),
                    nav:      true,
                    title:    'Lista de erros de processamento',
                    settings: {
                        menu:        'processing-errors-listing',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.processing-errors-listing' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },

                // ==================== management » additional-information » additional-information-types ====================
                {
                    route:    'management/additional-information/additional-information-types',
                    name:     'additional-information.additional-information-types.index', // Corrected here
                    moduleId: PLATFORM.moduleName('modules/management/additional-information/additional-information-types/index'),
                    nav:      true,
                    title:    'Gestão de tipos',
                    settings: {
                        menu:        'additional-information',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.additional-information' },
                            { title: 'page.breadcrumbs.additional-information-types' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'management/additional-information/additional-information-types/create',
                    name:     'additional-information.additional-information-types.create',
                    moduleId: PLATFORM.moduleName('modules/management/additional-information/additional-information-types/create/index'),
                    nav:      false,
                    title:    'Criar Tipo',
                    settings: {
                        menu:        'additional-information',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.additional-information' },
                            { title: 'page.breadcrumbs.additional-information-types' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'management/additional-information/additional-information-types/:id/edit',
                    name:     'additional-information.additional-information-types.edit',
                    moduleId: PLATFORM.moduleName('modules/management/additional-information/additional-information-types/edit/index'),
                    nav:      false,
                    title:    'Editar Tipo',
                    settings: {
                        menu:        'additional-information',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.additional-information' },
                            { title: 'page.breadcrumbs.additional-information-types' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'management/additional-information/additional-information-types/:id/view',
                    name:     'additional-information.additional-information-types.view',
                    moduleId: PLATFORM.moduleName('modules/management/additional-information/additional-information-types/view/index'),
                    nav:      false,
                    title:    'Visualizar Tipo',
                    settings: {
                        menu:        'additional-information',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.additional-information' },
                            { title: 'page.breadcrumbs.additional-information-types' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== additional-information » additional-informations ====================
                {
                    route:    'additional-information/additional-informations',
                    name:     'additional-information.additional-informations.index', // Corrected here
                    moduleId: PLATFORM.moduleName('modules/management/additional-information/additional-informations/index'),
                    nav:      true,
                    title:    'Informações Adicionais',
                    settings: {
                        menu:        'additional-information',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.additional-information' },
                            { title: 'page.breadcrumbs.register' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'additional-information/additional-informations/:id/view',
                    name:     'additional-information.additional-informations.view',
                    moduleId: PLATFORM.moduleName('modules/management/additional-information/additional-informations/view/index'),
                    nav:      false,
                    title:    'Registar',
                    settings: {
                        menu:        'additional-information',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.additional-information' },
                            { title: 'page.breadcrumbs.register' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },
                {
                    route:    'additional-information/additional-informations/create',
                    name:     'additional-information.additional-informations.create',
                    moduleId: PLATFORM.moduleName('modules/management/additional-information/additional-informations/create/index'),
                    nav:      false,
                    title:    'Registar',
                    settings: {
                        menu:        'additional-information',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.additional-information' },
                            { title: 'page.breadcrumbs.register' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'additional-information/additional-informations/:id/edit',
                    name:     'additional-information.additional-informations.edit',
                    moduleId: PLATFORM.moduleName('modules/management/additional-information/additional-informations/edit/index'),
                    nav:      false,
                    title:    'Editar Info. Adicional',
                    settings: {
                        menu:        'additional-information',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.additional-information' },
                            { title: 'page.breadcrumbs.register' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },

                // ==================== map » mat-app-proposals ====================
                {
                    route:    'map/mat-app-proposals',
                    name:     'map.mat-app-proposals.index',
                    moduleId: PLATFORM.moduleName('modules/map/mat-app-proposals/index'),
                    nav:      true,
                    title:    'PAM\'s',
                    settings: {
                        menu:        'map-mat-app-proposals',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.mat-app-proposal' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'map/mat-app-proposals/create',
                    name:     'map.mat-app-proposals.create',
                    moduleId: PLATFORM.moduleName('modules/map/mat-app-proposals/create/index'),
                    nav:      false,
                    title:    'Registar PAM',
                    settings: {
                        menu:        'map-mat-app-proposals',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.mat-app-proposal' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'map/mat-app-proposals/:id/edit',
                    name:     'map.mat-app-proposals.edit',
                    moduleId: PLATFORM.moduleName('modules/map/mat-app-proposals/edit/index'),
                    nav:      false,
                    title:    'Editar PAM',
                    settings: {
                        menu:        'map-mat-app-proposals',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.mat-app-proposal' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'map/mat-app-proposals/:id/view',
                    name:     'map.mat-app-proposals.view',
                    moduleId: PLATFORM.moduleName('modules/map/mat-app-proposals/view/index'),
                    nav:      false,
                    title:    'Visualizar PAM',
                    settings: {
                        menu:        'map-mat-app-proposals',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.mat-app-proposal' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== map » management » categories ====================
                {
                    route:    'map/management/categories',
                    name:     'map.management.categories.index',
                    moduleId: PLATFORM.moduleName('modules/map/management/categories/index'),
                    nav:      true,
                    title:    'Categorias',
                    settings: {
                        menu:        'map-categories',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.category' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'map/management/categories/create',
                    name:     'map.management.categories.create',
                    moduleId: PLATFORM.moduleName('modules/map/management/categories/create/index'),
                    nav:      false,
                    title:    'Criar Categoria',
                    settings: {
                        menu:        'map-categories',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.category' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'map/management/categories/:id/edit',
                    name:     'map.management.categories.edit',
                    moduleId: PLATFORM.moduleName('modules/map/management/categories/edit/index'),
                    nav:      false,
                    title:    'Editar Categoria',
                    settings: {
                        menu:        'map-categories',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.category' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'map/management/categories/:id/view',
                    name:     'map.management.categories.view',
                    moduleId: PLATFORM.moduleName('modules/map/management/categories/view/index'),
                    nav:      false,
                    title:    'Visualizar Categoria',
                    settings: {
                        menu:        'map-categories',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.category' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== map » management » applications ====================
                {
                    route:    'map/management/applications',
                    name:     'map.management.applications.index',
                    moduleId: PLATFORM.moduleName('modules/map/management/applications/index'),
                    nav:      true,
                    title:    'Aplicação',
                    settings: {
                        menu:        'map-applications',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.application' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'map/management/applications/create',
                    name:     'map.management.applications.create',
                    moduleId: PLATFORM.moduleName('modules/map/management/applications/create/index'),
                    nav:      false,
                    title:    'Criar Aplicação',
                    settings: {
                        menu:        'map-applications',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.application' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'map/management/applications/:id/edit',
                    name:     'map.management.applications.edit',
                    moduleId: PLATFORM.moduleName('modules/map/management/applications/edit/index'),
                    nav:      false,
                    title:    'Editar Aplicação',
                    settings: {
                        menu:        'map-applications',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.application' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'map/management/applications/:id/view',
                    name:     'map.management.applications.view',
                    moduleId: PLATFORM.moduleName('modules/map/management/applications/view/index'),
                    nav:      false,
                    title:    'Visualizar Aplicação',
                    settings: {
                        menu:        'map-applications',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.application' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== map » management » manufacturers ====================
                {
                    route:    'map/management/manufacturers',
                    name:     'map.management.manufacturers.index',
                    moduleId: PLATFORM.moduleName('modules/map/management/manufacturers/index'),
                    nav:      true,
                    title:    'Fabricante',
                    settings: {
                        menu:        'manufacturers',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.manufacturer' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'map/management/manufacturers/create',
                    name:     'map.management.manufacturers.create',
                    moduleId: PLATFORM.moduleName('modules/map/management/manufacturers/create/index'),
                    nav:      false,
                    title:    'Criar Fabricante',
                    settings: {
                        menu:        'manufacturers',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.manufacturer' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'map/management/manufacturers/:id/edit',
                    name:     'map.management.manufacturers.edit',
                    moduleId: PLATFORM.moduleName('modules/map/management/manufacturers/edit/index'),
                    nav:      false,
                    title:    'Editar Fabricante',
                    settings: {
                        menu:        'manufacturers',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.manufacturer' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'map/management/manufacturers/:id/view',
                    name:     'map.management.manufacturers.view',
                    moduleId: PLATFORM.moduleName('modules/map/management/manufacturers/view/index'),
                    nav:      false,
                    title:    'Visualizar Fabricante',
                    settings: {
                        menu:        'manufacturers',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.manufacturer' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== map » management » suppliers ====================
                {
                    route:    'map/management/suppliers',
                    name:     'map.management.suppliers.index',
                    moduleId: PLATFORM.moduleName('modules/map/management/suppliers/index'),
                    nav:      true,
                    title:    'Fornecedor',
                    settings: {
                        menu:        'suppliers',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.supplier' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'map/management/suppliers/create',
                    name:     'map.management.suppliers.create',
                    moduleId: PLATFORM.moduleName('modules/map/management/suppliers/create/index'),
                    nav:      false,
                    title:    'Criar Fornecedor',
                    settings: {
                        menu:        'suppliers',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.supplier' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'map/management/suppliers/:id/edit',
                    name:     'map.management.suppliers.edit',
                    moduleId: PLATFORM.moduleName('modules/map/management/suppliers/edit/index'),
                    nav:      false,
                    title:    'Editar Fornecedor',
                    settings: {
                        menu:        'suppliers',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.supplier' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'map/management/suppliers/:id/view',
                    name:     'map.management.suppliers.view',
                    moduleId: PLATFORM.moduleName('modules/map/management/suppliers/view/index'),
                    nav:      false,
                    title:    'Visualizar Fornecedor',
                    settings: {
                        menu:        'suppliers',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.supplier' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== aggregates » not-treated » lab ====================
                {
                    route:    'aggregates/not-treated/lab',
                    name:     'aggregates.not-treated.lab.index',
                    moduleId: PLATFORM.moduleName('modules/aggregates/lab/index'),
                    href:     'aggregates/not-treated/lab', // Default value
                    nav:      true,
                    title:    'Agregados - Ensaios Laboratoriais',
                    settings: {
                        menu:        'aggregates-lab',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.aggregates' },
                            { title: 'page.breadcrumbs.not-treated' },
                            { title: 'page.breadcrumbs.lab' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'aggregates/not-treated/lab/create',
                    name:     'aggregates.not-treated.lab.create',
                    moduleId: PLATFORM.moduleName('modules/aggregates/lab/create/index'),
                    nav:      false,
                    title:    'Criar Ensaio',
                    settings: {
                        menu:        'aggregates-lab',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.aggregates' },
                            { title: 'page.breadcrumbs.not-treated' },
                            { title: 'page.breadcrumbs.lab' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'aggregates/not-treated/lab/:id/edit',
                    name:     'aggregates.not-treated.lab.edit',
                    moduleId: PLATFORM.moduleName('modules/aggregates/lab/edit/index'),
                    nav:      false,
                    title:    'Editar Ensaio',
                    settings: {
                        menu:        'aggregates-lab',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.aggregates' },
                            { title: 'page.breadcrumbs.not-treated' },
                            { title: 'page.breadcrumbs.lab' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'aggregates/not-treated/lab/:id/view',
                    name:     'aggregates.not-treated.lab.view',
                    moduleId: PLATFORM.moduleName('modules/aggregates/lab/view/index'),
                    nav:      false,
                    title:    'Visualizar Ensaio',
                    settings: {
                        menu:        'aggregates-lab',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.aggregates' },
                            { title: 'page.breadcrumbs.not-treated' },
                            { title: 'page.breadcrumbs.lab' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== aggregates » treated » lab ====================
                {
                    route:    'aggregates/treated/lab',
                    name:     'aggregates.treated.lab.index',
                    moduleId: PLATFORM.moduleName('modules/aggregates/lab/index'),
                    href:     'aggregates/treated/lab', // Default value
                    nav:      true,
                    title:    'Agregados - Ensaios Laboratoriais',
                    settings: {
                        menu:        'aggregates-lab',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.aggregates' },
                            { title: 'page.breadcrumbs.treated' },
                            { title: 'page.breadcrumbs.lab' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'aggregates/treated/lab/create',
                    name:     'aggregates.treated.lab.create',
                    moduleId: PLATFORM.moduleName('modules/aggregates/lab/create/index'),
                    nav:      false,
                    title:    'Criar Ensaio',
                    settings: {
                        menu:        'aggregates-lab',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.aggregates' },
                            { title: 'page.breadcrumbs.treated' },
                            { title: 'page.breadcrumbs.lab' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'aggregates/treated/lab/:id/edit',
                    name:     'aggregates.treated.lab.edit',
                    moduleId: PLATFORM.moduleName('modules/aggregates/lab/edit/index'),
                    nav:      false,
                    title:    'Editar Ensaio',
                    settings: {
                        menu:        'aggregates-lab',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.aggregates' },
                            { title: 'page.breadcrumbs.treated' },
                            { title: 'page.breadcrumbs.lab' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'aggregates/treated/lab/:id/view',
                    name:     'aggregates.treated.lab.view',
                    moduleId: PLATFORM.moduleName('modules/aggregates/lab/view/index'),
                    nav:      false,
                    title:    'Visualizar Ensaio',
                    settings: {
                        menu:        'aggregates-lab',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.aggregates' },
                            { title: 'page.breadcrumbs.treated' },
                            { title: 'page.breadcrumbs.lab' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== aggregates » not-treated » in-situ ====================
                {
                    route:    'aggregates/not-treated/in-situ',
                    name:     'aggregates.not-treated.in-situ.index',
                    moduleId: PLATFORM.moduleName('modules/aggregates/in-situ/index'),
                    href:     'aggregates/not-treated/in-situ', // Default value
                    nav:      true,
                    title:    'Agregados - In Situ',
                    settings: {
                        menu:        'aggregates-in-situ',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.aggregates' },
                            { title: 'page.breadcrumbs.not-treated' },
                            { title: 'page.breadcrumbs.in-situ' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'aggregates/not-treated/in-situ/create',
                    name:     'aggregates.not-treated.in-situ.create',
                    moduleId: PLATFORM.moduleName('modules/aggregates/in-situ/create/index'),
                    nav:      false,
                    title:    'Criar Ensaio',
                    settings: {
                        menu:        'aggregates-in-situ',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.aggregates' },
                            { title: 'page.breadcrumbs.not-treated' },
                            { title: 'page.breadcrumbs.in-situ' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'aggregates/not-treated/in-situ/:id/edit',
                    name:     'aggregates.not-treated.in-situ.edit',
                    moduleId: PLATFORM.moduleName('modules/aggregates/in-situ/edit/index'),
                    nav:      false,
                    title:    'Editar Ensaio',
                    settings: {
                        menu:        'aggregates-in-situ',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.aggregates' },
                            { title: 'page.breadcrumbs.not-treated' },
                            { title: 'page.breadcrumbs.in-situ' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'aggregates/not-treated/in-situ/:id/view',
                    name:     'aggregates.not-treated.in-situ.view',
                    moduleId: PLATFORM.moduleName('modules/aggregates/in-situ/view/index'),
                    nav:      false,
                    title:    'Visualizar Ensaio',
                    settings: {
                        menu:        'aggregates-in-situ',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.aggregates' },
                            { title: 'page.breadcrumbs.not-treated' },
                            { title: 'page.breadcrumbs.in-situ' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },
                // ==================== aggregates » treated » in-situ ====================
                {
                    route:    'aggregates/treated/in-situ',
                    name:     'aggregates.treated.in-situ.index',
                    moduleId: PLATFORM.moduleName('modules/aggregates/in-situ/index'),
                    href:     'aggregates/treated/in-situ', // Default value
                    nav:      true,
                    title:    'Agregados - In Situ',
                    settings: {
                        menu:        'aggregates-in-situ',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.aggregates' },
                            { title: 'page.breadcrumbs.treated' },
                            { title: 'page.breadcrumbs.in-situ' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'aggregates/treated/in-situ/create',
                    name:     'aggregates.treated.in-situ.create',
                    moduleId: PLATFORM.moduleName('modules/aggregates/in-situ/create/index'),
                    nav:      false,
                    title:    'Criar Ensaio',
                    settings: {
                        menu:        'aggregates-in-situ',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.aggregates' },
                            { title: 'page.breadcrumbs.treated' },
                            { title: 'page.breadcrumbs.in-situ' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'aggregates/treated/in-situ/:id/edit',
                    name:     'aggregates.treated.in-situ.edit',
                    moduleId: PLATFORM.moduleName('modules/aggregates/in-situ/edit/index'),
                    nav:      false,
                    title:    'Editar Ensaio',
                    settings: {
                        menu:        'aggregates-in-situ',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.aggregates' },
                            { title: 'page.breadcrumbs.treated' },
                            { title: 'page.breadcrumbs.in-situ' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'aggregates/treated/in-situ/:id/view',
                    name:     'aggregates.treated.in-situ.view',
                    moduleId: PLATFORM.moduleName('modules/aggregates/in-situ/view/index'),
                    nav:      false,
                    title:    'Visualizar Ensaio',
                    settings: {
                        menu:        'aggregates-in-situ',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.aggregates' },
                            { title: 'page.breadcrumbs.treated' },
                            { title: 'page.breadcrumbs.in-situ' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // =============================== bituminous-mixtures ===============================
                // ==================== bituminous-mixtures » ligands ================================
                {
                    route:    'bituminous-mixtures/ligands',
                    name:     'bituminous-mixtures.ligands.index',
                    moduleId: PLATFORM.moduleName('modules/bituminous-mixtures/ligands/index'),
                    nav:      true,
                    title:    'Misturas Betuminosas - Ligantes',
                    settings: {
                        menu:        'bm-ligands',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.ligands' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'bituminous-mixtures/ligands/create',
                    name:     'bituminous-mixtures.ligands.create',
                    moduleId: PLATFORM.moduleName('modules/bituminous-mixtures/ligands/create/index'),
                    nav:      false,
                    title:    'Criar Ligante',
                    settings: {
                        menu:        'bm-ligands',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.ligands' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'bituminous-mixtures/ligands/:id/edit',
                    name:     'bituminous-mixtures.ligands.edit',
                    moduleId: PLATFORM.moduleName('modules/bituminous-mixtures/ligands/edit/index'),
                    nav:      false,
                    title:    'Editar Ligante',
                    settings: {
                        menu:        'bm-ligands',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.ligands' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'bituminous-mixtures/ligands/:id/view',
                    name:     'bituminous-mixtures.ligands.view',
                    moduleId: PLATFORM.moduleName('modules/bituminous-mixtures/ligands/view/index'),
                    nav:      false,
                    title:    'Visualizar Ligante',
                    settings: {
                        menu:        'bm-ligands',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.management' },
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.ligands' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },
                // ==================== bituminous-mixtures » mixtures » formulation-studies ====================
                {
                    route:    'bituminous-mixtures/mixtures/formulation-studies',
                    name:     'bituminous-mixtures.mixtures.formulation-studies.index',
                    moduleId: PLATFORM.moduleName('modules/bituminous-mixtures/mixtures/formulation-studies/index'),
                    nav:      true,
                    title:    'E. Formulação',
                    settings: {
                        menu:        'formulation-studies',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.mixtures' },
                            { title: 'page.breadcrumbs.formulation-studies' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'bituminous-mixtures/mixtures/formulation-studies/create',
                    name:     'bituminous-mixtures.mixtures.formulation-studies.create',
                    moduleId: PLATFORM.moduleName('modules/bituminous-mixtures/mixtures/formulation-studies/create/index'),
                    nav:      false,
                    title:    'Criar E. Formulação',
                    settings: {
                        menu:        'formulation-studies',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.mixtures' },
                            { title: 'page.breadcrumbs.formulation-studies' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'bituminous-mixtures/mixtures/formulation-studies/:id/edit',
                    name:     'bituminous-mixtures.mixtures.formulation-studies.edit',
                    moduleId: PLATFORM.moduleName('modules/bituminous-mixtures/mixtures/formulation-studies/edit/index'),
                    nav:      false,
                    title:    'Editar E. Formulação',
                    settings: {
                        menu:        'formulation-studies',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.mixtures' },
                            { title: 'page.breadcrumbs.formulation-studies' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'bituminous-mixtures/mixtures/formulation-studies/:id/view',
                    name:     'bituminous-mixtures.mixtures.formulation-studies.view',
                    moduleId: PLATFORM.moduleName('modules/bituminous-mixtures/mixtures/formulation-studies/view/index'),
                    nav:      false,
                    title:    'Visualizar E. Formulação',
                    settings: {
                        menu:        'formulation-studies',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.mixtures' },
                            { title: 'page.breadcrumbs.formulation-studies' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },
                // ==================== bituminous-mixtures » mixtures » manufacturing-plant-transposition ====================
                {
                    route:    'bituminous-mixtures/mixtures/manufacturing-plant-transpositions',
                    name:     'bituminous-mixtures.mixtures.manufacturing-plant-transpositions.index',
                    moduleId: PLATFORM.moduleName('modules/bituminous-mixtures/mixtures/manufacturing-plant-transpositions/index'),
                    nav:      true,
                    title:    'T.P. Central de Fabrico',
                    settings: {
                        menu:        'manufacturing-plant-transpositions',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.mixtures' },
                            { title: 'page.breadcrumbs.manufacturing-plant-transpositions' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'bituminous-mixtures/mixtures/manufacturing-plant-transpositions/create',
                    name:     'bituminous-mixtures.mixtures.manufacturing-plant-transpositions.create',
                    moduleId: PLATFORM.moduleName('modules/bituminous-mixtures/mixtures/manufacturing-plant-transpositions/create/index'),
                    nav:      false,
                    title:    'Criar TPCF',
                    settings: {
                        menu:        'manufacturing-plant-transpositions',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.mixtures' },
                            { title: 'page.breadcrumbs.manufacturing-plant-transpositions' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'bituminous-mixtures/mixtures/manufacturing-plant-transpositions/:id/edit',
                    name:     'bituminous-mixtures.mixtures.manufacturing-plant-transpositions.edit',
                    moduleId: PLATFORM.moduleName('modules/bituminous-mixtures/mixtures/manufacturing-plant-transpositions/edit/index'),
                    nav:      false,
                    title:    'Editar TPCF',
                    settings: {
                        menu:        'manufacturing-plant-transpositions',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.mixtures' },
                            { title: 'page.breadcrumbs.manufacturing-plant-transpositions' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'bituminous-mixtures/mixtures/manufacturing-plant-transpositions/:id/view',
                    name:     'bituminous-mixtures.mixtures.manufacturing-plant-transpositions.view',
                    moduleId: PLATFORM.moduleName('modules/bituminous-mixtures/mixtures/manufacturing-plant-transpositions/view/index'),
                    nav:      false,
                    title:    'Visualizar TPCF',
                    settings: {
                        menu:        'manufacturing-plant-transpositions',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.mixtures' },
                            { title: 'page.breadcrumbs.manufacturing-plant-transpositions' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },
                // ==================== bituminous-mixtures » mixtures » approved-working-formula ====================
                {
                    route:    'bituminous-mixtures/mixtures/approved-working-formulas',
                    name:     'bituminous-mixtures.mixtures.approved-working-formulas.index',
                    moduleId: PLATFORM.moduleName('modules/bituminous-mixtures/mixtures/approved-working-formulas/index'),
                    nav:      true,
                    title:    'F.T. Aprovada',
                    settings: {
                        menu:        'approved-working-formulas',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.mixtures' },
                            { title: 'page.breadcrumbs.approved-working-formulas' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'bituminous-mixtures/mixtures/approved-working-formulas/create',
                    name:     'bituminous-mixtures.mixtures.approved-working-formulas.create',
                    moduleId: PLATFORM.moduleName('modules/bituminous-mixtures/mixtures/approved-working-formulas/create/index'),
                    nav:      false,
                    title:    'Criar FTA',
                    settings: {
                        menu:        'approved-working-formulas',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.mixtures' },
                            { title: 'page.breadcrumbs.approved-working-formulas' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'bituminous-mixtures/mixtures/approved-working-formulas/:id/edit',
                    name:     'bituminous-mixtures.mixtures.approved-working-formulas.edit',
                    moduleId: PLATFORM.moduleName('modules/bituminous-mixtures/mixtures/approved-working-formulas/edit/index'),
                    nav:      false,
                    title:    'Editar FTA',
                    settings: {
                        menu:        'approved-working-formulas',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.mixtures' },
                            { title: 'page.breadcrumbs.approved-working-formulas' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'bituminous-mixtures/mixtures/approved-working-formulas/:id/view',
                    name:     'bituminous-mixtures.mixtures.approved-working-formulas.view',
                    moduleId: PLATFORM.moduleName('modules/bituminous-mixtures/mixtures/approved-working-formulas/view/index'),
                    nav:      false,
                    title:    'Visualizar FTA',
                    settings: {
                        menu:        'approved-working-formulas',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.mixtures' },
                            { title: 'page.breadcrumbs.approved-working-formulas' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== bituminous-mixtures » lab-tests ====================
                {
                    route:    'bituminous-mixtures/mixtures/lab',
                    name:     'bituminous-mixtures.mixtures.lab.index',
                    moduleId: PLATFORM.moduleName('modules/bituminous-mixtures/mixtures/lab/index'),
                    nav:      true,
                    title:    'Misturas Laboratoriais',
                    settings: {
                        menu:        'lab',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.mixtures' },
                            { title: 'page.breadcrumbs.lab' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'bituminous-mixtures/mixtures/lab/create',
                    name:     'bituminous-mixtures.mixtures.lab.create',
                    moduleId: PLATFORM.moduleName('modules/bituminous-mixtures/mixtures/lab/create/index'),
                    nav:      false,
                    title:    'Criar Misturas Laboratoriais',
                    settings: {
                        menu:        'lab',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.mixtures' },
                            { title: 'page.breadcrumbs.lab' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'bituminous-mixtures/mixtures/lab/:id/edit',
                    name:     'bituminous-mixtures.mixtures.lab.edit',
                    moduleId: PLATFORM.moduleName('modules/bituminous-mixtures/mixtures/lab/edit/index'),
                    nav:      false,
                    title:    'Editar Misturas Laboratoriais',
                    settings: {
                        menu:        'lab',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.mixtures' },
                            { title: 'page.breadcrumbs.lab' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'bituminous-mixtures/mixtures/lab/:id/view',
                    name:     'bituminous-mixtures.mixtures.lab.view',
                    moduleId: PLATFORM.moduleName('modules/bituminous-mixtures/mixtures/lab/view/index'),
                    nav:      false,
                    title:    'Visualizar Misturas Laboratoriais',
                    settings: {
                        menu:        'lab',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.mixtures' },
                            { title: 'page.breadcrumbs.lab' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== bituminous-mixtures » in-situ-tests ====================
                {
                    route:    'bituminous-mixtures/mixtures/in-situ',
                    name:     'bituminous-mixtures.mixtures.in-situ.index',
                    moduleId: PLATFORM.moduleName('modules/bituminous-mixtures/mixtures/in-situ/index'),
                    nav:      true,
                    title:    'Misturas In Situ',
                    settings: {
                        menu:        'in-situ',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.mixtures' },
                            { title: 'page.breadcrumbs.in-situ' },
                            { title: 'page.breadcrumbs.listing' },
                        ],
                    },
                },
                {
                    route:    'bituminous-mixtures/mixtures/in-situ/create',
                    name:     'bituminous-mixtures.mixtures.in-situ.create',
                    moduleId: PLATFORM.moduleName('modules/bituminous-mixtures/mixtures/in-situ/create/index'),
                    nav:      false,
                    title:    'Criar Misturas In Situ',
                    settings: {
                        menu:        'in-situ',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.mixtures' },
                            { title: 'page.breadcrumbs.in-situ' },
                            { title: 'page.breadcrumbs.new-record' },
                        ],
                    },
                },
                {
                    route:    'bituminous-mixtures/mixtures/in-situ/:id/edit',
                    name:     'bituminous-mixtures.mixtures.in-situ.edit',
                    moduleId: PLATFORM.moduleName('modules/bituminous-mixtures/mixtures/in-situ/edit/index'),
                    nav:      false,
                    title:    'Editar Misturas In Situ',
                    settings: {
                        menu:        'in-situ',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.mixtures' },
                            { title: 'page.breadcrumbs.in-situ' },
                            { title: 'page.breadcrumbs.edit-record' },
                        ],
                    },
                },
                {
                    route:    'bituminous-mixtures/mixtures/in-situ/:id/view',
                    name:     'bituminous-mixtures.mixtures.in-situ.view',
                    moduleId: PLATFORM.moduleName('modules/bituminous-mixtures/mixtures/in-situ/view/index'),
                    nav:      false,
                    title:    'Visualizar Misturas In Situ',
                    settings: {
                        menu:        'in-situ',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.mixtures' },
                            { title: 'page.breadcrumbs.in-situ' },
                            { title: 'page.breadcrumbs.view-record' },
                        ],
                    },
                },

                // ==================== listings ====================
                {
                    route:    'listings',
                    name:     'listings',
                    moduleId: PLATFORM.moduleName('modules/dynamic-lists/lab/index'),
                    nav:      true,
                    title:    'Listings',
                },

                // ==================== dynamic-lists » mixtures » manufacturing-plant-transposition ====================
                {
                    route:    'dynamic-lists/mixtures/manufacturing-plant-transpositions',
                    name:     'dynamic-lists.mixtures.manufacturing-plant-transpositions.index',
                    moduleId: PLATFORM.moduleName('modules/dynamic-lists/mixtures/manufacturing-plant-transpositions/index'),
                    nav:      true,
                    title:    'Listagens » Misturas » TPCF',
                    settings: {
                        menu:        'listings',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.dynamic-listings' },
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.tpcf' },
                        ],
                    },
                },

                // ==================== dynamic-lists »  mixtures » formulation-studies ====================
                {
                    route:    'dynamic-lists/mixtures/formulation-studies',
                    name:     'dynamic-lists.mixtures.formulation-studies.index',
                    moduleId: PLATFORM.moduleName('modules/dynamic-lists/mixtures/formulation-studies/index'),
                    nav:      true,
                    title:    'Listagens » Misturas » E. Formulação',
                    settings: {
                        menu:        'listings',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.dynamic-listings' },
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.formulation-study' },
                        ],
                    },
                },

                // ==================== dynamic-lists »  mixtures » approved-working-formulas ====================
                {
                    route:    'dynamic-lists/mixtures/approved-working-formulas',
                    name:     'dynamic-lists.mixtures.approved-working-formulas.index',
                    moduleId: PLATFORM.moduleName('modules/dynamic-lists/mixtures/approved-working-formulas/index'),
                    nav:      true,
                    title:    'Listagens » Misturas » F.T. Aprovada',
                    settings: {
                        menu:        'listings',
                        breadcrumbs: [
                            { title: 'page.breadcrumbs.dynamic-listings' },
                            { title: 'page.breadcrumbs.bituminous-mixtures' },
                            { title: 'page.breadcrumbs.fta' },
                        ],
                    },
                },
            ]);

            config.fallbackRoute('dashboard');
        };

        this.router.configure(appRouterConfig);
    }

}
